import React from "react";
import classes from "./HowToBuy.module.scss";
import image from "../../../assets/img/LearnPage/how_to_buy.png";

import { useTranslation } from "react-i18next";

const HowToBuy = () => {
  const { t } = useTranslation();
  return (
    <section className="container">
      <div className={classes.howToBuy}>
        <h2 className={[classes.title, "font-45"].join(" ")}>
          {t("learn_7_1")}
        </h2>
        <p className={[classes.text, "font-20"].join(" ")}>{t("learn_7_2")}</p>
        <div className={[classes.content, "font-20"].join(" ")}>
          <div className={classes.side}>
            <h3 className={classes.sideTitle}>{t("learn_7_left1")}</h3>
            <p className={classes.sideDesc}>{t("learn_7_left2")}</p>
            <p className={classes.sideText}>{t("learn_7_left3")}</p>
          </div>
          <img
            className={[classes.image, "no-select"].join(" ")}
            src={image}
            alt={t("learn_7_1")}
          />
          <div className={classes.side}>
            <h3 className={classes.sideTitle}>{t("learn_7_right1")}</h3>
            <p className={classes.sideDesc}>{t("learn_7_right2")}</p>
            <p className={classes.sideText}>{t("learn_7_right3")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToBuy;
