import React from "react";
import classes from "./Button.module.scss";
import "./Button.scss";
import Cookies from 'js-cookie';

const Button = (props) => {
  const lang = Cookies.get('i18next');
  return (
    <a href={`https://client.xpinvestment.one/signup?lang=${lang}`} className={classes.mainBtn} {...props}>
      <span>{props.children}</span>
      <div className="button-container-1">
        <span className="mas"> </span>
        <button id="work" type="button" name="Hover"></button>
      </div>
    </a>
  );
};

export default Button;
