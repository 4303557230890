import MainPage from "../pages/MainPage/MainPage";
import AboutPage from "../pages/AboutPage/AboutPage";
import AccountsPage from "../pages/AccountsPage/AccountsPage";
import LearnPage from "../pages/LearnPage/LearnPage";
import StartPage from "../pages/StartPage/StartPage";
import TradingSpacePage from "../pages/TradingSpacePage/TradingSpacePage";
import VattenfallPage from "../pages/VattenfallPage/VattenfallPage";

export const routes = [
    { path: '*', element: MainPage },
    { path: '/about', element: AboutPage },
    { path: '/accounts-and-instruments', element: AccountsPage },
    { path: '/learn-to-trade', element: LearnPage },
    { path: '/start', element: StartPage },
    { path: '/trading-space', element: TradingSpacePage },
    { path: '/vattenfall', element: VattenfallPage },
];

export const headerRoutes = [
    { id: 4, path: '/start', text: "header_item_4" },
    { id: 2, path: '/accounts-and-instruments', text: "header_item_2" },
    { id: 5, path: '/trading-space', text: "header_item_5" },
    { id: 3, path: '/learn-to-trade', text: "header_item_3" },
    { id: 1, path: '/about', text: "header_item_1" },
    { id: 6, path: '/vattenfall', text: "Vattenfall" },
]