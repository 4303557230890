import img1 from "./assets/img/MainPage/1/bg.png"
import img2 from "./assets/img/MainPage/1/bg1.png"
import img3 from "./assets/img/MainPage/1/bg2.png"
import img4 from "./assets/img/MainPage/1/content.png"
import img5 from "./assets/img/MainPage/1/content1.png"
import img6 from "./assets/img/MainPage/1/content2.png"
import img7 from "./assets/img/MainPage/1/g.png"

import img8 from "./assets/img/AccountsPage/Banner/bg.png"
import img9 from "./assets/img/AccountsPage/Banner/image.png"

import img10 from "./assets/img/LearnPage/banner.gif"

import img11 from "./assets/img/StartPage/Banner/bg.png"
import img12 from "./assets/img/StartPage/Banner/image.png"

import img13 from "./assets/img/TradingSpace/1/1.png"
import img14 from "./assets/img/TradingSpace/1/2.png"
import img15 from "./assets/img/TradingSpace/1/11.png"
import img16 from "./assets/img/TradingSpace/1/22.png"
import img17 from "./assets/img/TradingSpace/1/111.png"

import img18 from "./assets/img/AboutPage/banner.png"

import vattenfall from './assets/img/VattenfallPage/banner.png';







export const IMAGES = [
    {
        id: 0,
        url:
            img1
    },
    {
        id: 1,
        url:
            img2
    },
    {
        id: 2,
        url:
            img3
    },
    {
        id: 3,
        url:
            img4
    },
    {
        id: 4,
        url:
            img5
    },
    {
        id: 5,
        url:
            img6
    },
    {
        id: 6,
        url:
            img7
    },
    {
        id: 7,
        url:
            img8
    },
    {
        id: 8,
        url:
            img9
    },
    {
        id: 9,
        url:
            img10
    },
    {
        id: 10,
        url:
            img11
    },
    {
        id: 11,
        url:
            img12
    },
    {
        id: 12,
        url:
            img13
    },
    {
        id: 13,
        url:
            img14
    },
    {
        id: 14,
        url:
            img15
    },
    {
        id: 15,
        url:
            img16
    },
    {
        id: 16,
        url:
            img17
    },
    {
        id: 17,
        url:
            img18
    },
    {
        id: 18,
        url:
            vattenfall
    },
];
