import React, { useState, useEffect } from "react";
import classes from "./Stages.module.scss";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

const Stages = () => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: t("main_5_top1"),
      content: [
        [
          t("main_5_content1_1"),
          t("main_5_content1_2"),
          t("main_5_content1_3"),
          t("main_5_content1_4"),
          t("main_5_content1_5"),
          t("main_5_content1_6"),
        ],
      ],
      active: true,
    },
    {
      id: 2,
      title: t("main_5_top2"),

      content: [
        [
          t("main_5_content2_1"),
          t("main_5_content2_2"),
          t("main_5_content2_3"),
          t("main_5_content2_4"),
          t("main_5_content2_5"),
          t("main_5_content2_6"),
        ],
      ],
      active: false,
    },
    {
      id: 3,
      title: t("main_5_top3"),

      content: [
        [
          t("main_5_content3_1"),
          t("main_5_content3_2"),
          t("main_5_content3_3"),
          t("main_5_content3_4"),
          t("main_5_content3_5"),
          t("main_5_content3_6"),
        ],
      ],
      active: false,
    },
    {
      id: 4,
      title: t("main_5_top4"),

      content: [
        [
          t("main_5_content4_1"),
          t("main_5_content4_2"),
          t("main_5_content4_3"),
          t("main_5_content4_4"),
          t("main_5_content4_5"),
          t("main_5_content4_6"),
        ],
      ],
      active: false,
    },
    {
      id: 5,
      title: t("main_5_top5"),

      content: [
        [
          t("main_5_content5_1"),
          t("main_5_content5_2"),
          t("main_5_content5_3"),
          t("main_5_content5_4"),
          t("main_5_content5_5"),
          t("main_5_content5_6"),
        ],
      ],
      active: false,
    },
    {
      id: 6,
      title: t("main_5_top6"),

      content: [
        [
          t("main_5_content6_1"),
          t("main_5_content6_2"),
          t("main_5_content6_3"),
          t("main_5_content6_4"),
          t("main_5_content6_5"),
          t("main_5_content6_6"),
        ],
      ],
      active: false,
    },
  ]);

  useEffect(() => {
    setTabs([
      {
        id: 1,
        title: t("main_5_top1"),
        content: [
          [
            t("main_5_content1_1"),
            t("main_5_content1_2"),
            t("main_5_content1_3"),
            t("main_5_content1_4"),
            t("main_5_content1_5"),
            t("main_5_content1_6"),
          ],
        ],
        active: true,
      },
      {
        id: 2,
        title: t("main_5_top2"),

        content: [
          [
            t("main_5_content2_1"),
            t("main_5_content2_2"),
            t("main_5_content2_3"),
            t("main_5_content2_4"),
            t("main_5_content2_5"),
            t("main_5_content2_6"),
          ],
        ],
        active: false,
      },
      {
        id: 3,
        title: t("main_5_top3"),

        content: [
          [
            t("main_5_content3_1"),
            t("main_5_content3_2"),
            t("main_5_content3_3"),
            t("main_5_content3_4"),
            t("main_5_content3_5"),
            t("main_5_content3_6"),
          ],
        ],
        active: false,
      },
      {
        id: 4,
        title: t("main_5_top4"),

        content: [
          [
            t("main_5_content4_1"),
            t("main_5_content4_2"),
            t("main_5_content4_3"),
            t("main_5_content4_4"),
            t("main_5_content4_5"),
            t("main_5_content4_6"),
          ],
        ],
        active: false,
      },
      {
        id: 5,
        title: t("main_5_top5"),

        content: [
          [
            t("main_5_content5_1"),
            t("main_5_content5_2"),
            t("main_5_content5_3"),
            t("main_5_content5_4"),
            t("main_5_content5_5"),
            t("main_5_content5_6"),
          ],
        ],
        active: false,
      },
      {
        id: 6,
        title: t("main_5_top6"),

        content: [
          [
            t("main_5_content6_1"),
            t("main_5_content6_2"),
            t("main_5_content6_3"),
            t("main_5_content6_4"),
            t("main_5_content6_5"),
            t("main_5_content6_6"),
          ],
        ],
        active: false,
      },
    ]);
    // eslint-disable-next-line
  }, [t("lang")]);

  return (
    <div className={classes.stages}>
      <div className={classes.stagesBody}>
        <div className="container">
          <div className={[classes.stagesTitle, "font-45"].join(" ")}>
            {t("main_5_title")}
          </div>
          <div className={classes.stagesWrap}>
            <div className={classes.stagesTop}>
              {tabs.map((topI) => {
                return (
                  <div
                    key={topI.id + 5}
                    onClick={() => {
                      setTabs(
                        tabs.map((tabI) => {
                          if (topI.id === tabI.id) {
                            return { ...tabI, active: true };
                          } else {
                            return { ...tabI, active: false };
                          }
                        })
                      );
                    }}
                    className={
                      topI.active
                        ? [
                            classes.stagesTopColumn,
                            classes.active,
                            "font-20",
                          ].join(" ")
                        : [classes.stagesTopColumn, "font-20"].join(" ")
                    }
                  >
                    <span>{topI.title}</span>
                  </div>
                );
              })}
            </div>
            {tabs.map((centerI) => {
              return (
                <div
                  key={uuidv4()}
                  className={
                    centerI.active
                      ? [classes.stagesCenter, classes.active].join(" ")
                      : classes.stagesCenter
                  }
                >
                  {centerI.content.map((subI) => {
                    if (typeof subI === "string") {
                      return (
                        <div
                          key={uuidv4()}
                          className={[classes.stagesCenterText, "font-20"].join(
                            " "
                          )}
                        >
                          {subI}
                        </div>
                      );
                    } else {
                      return (
                        <ul key={uuidv4()} className={classes.stagesCenterList}>
                          {subI.map((listI) => {
                            return (
                              <li
                                key={uuidv4()}
                                className={["font-20"].join(" ")}
                              >
                                {listI}
                              </li>
                            );
                          })}
                        </ul>
                      );
                    }
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stages;
