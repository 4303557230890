import React from "react";
import classes from './PowerRevolution.module.scss';
import powerRevolution from '../../../assets/img/VattenfallPage/powerRevolution.png';

const PowerRevolution = () => {
    return (
        <section className="mt container">
            <div className={classes.container}>
                <div className={classes.col}>
                    <h2 className={[classes.title, 'font-45'].join(' ')}>
                        Power revolution by Vattenfall
                    </h2>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            Vattenfall fuels its power generation initiatives with various renewable energy sources, including wind and heat. We actively use the power of wind turbines to capture and convert wind energy into clean electricity as part of a holistic approach to sustainable energy. 
                        </p>
                        <p>
                            Vattenfall intends to drive the transition to a greener and more sustainable energy system by integrating these diverse renewable energy production sources and employing innovative energy distribution methods.
                        </p>
                    </div>
                </div>
                <div className={classes.col}>
                    <img className={classes.image} src={powerRevolution} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default PowerRevolution;
