import React, { useRef, useEffect } from "react";
import img1 from "../../../assets/img/MainPage/5/1.png";
import classes from "./Platform.module.scss";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Platform = () => {
  const mainRef = useRef(null);
  const ball1 = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(ball1.current, {
        scale: 1,

        scrollTrigger: {
          trigger: mainRef.current,
          start: "top 400px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, []);
  return (
    <div className={classes.platform} ref={mainRef}>
      <div className={classes.platformBody}>
        <div className="container">
          <div className={classes.platformRow}>
            <div className={classes.platformRowleft}>
              <div
                className={[classes.platformRowleftTitle, "font-45"].join(" ")}
              >
                {t("main_6_1")}
              </div>
              <div
                className={[classes.platformRowleftSubtitle, "font-20"].join(
                  " "
                )}
              >
                {t("main_6_2")}
              </div>
              <div
                className={[classes.platformRowleftSubtitle, "font-20"].join(
                  " "
                )}
              >
                {t("main_6_3")}
              </div>
            </div>
            <div className={classes.platformRowRight} ref={ball1}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platform;
