import React from "react";
import classes from "./SecurityClients.module.scss";
import img1 from "../../../assets/img/AboutPage/sec-clients-img-1.png";
import img2 from "../../../assets/img/AboutPage/sec-clients-img-2.png";
import img3 from "../../../assets/img/AboutPage/sec-clients-img-3.png";
import { useTranslation } from "react-i18next";

const SecurityClients = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.securityClientsContainer, "container"].join(" ")}>
      <h3 className={[classes.securityClientsTitle, "font-45 text-center"].join(" ")}>
        {t("about_3_1")}
      </h3>
      <p
        className={[
          classes.securityClientsText,
          "font-20 fw-bolder text-center",
        ].join(" ")}
      >
        {t("about_3_2")}
      </p>
      <div className={classes.securityClientsOverflow}>
        <div className={classes.securityClientsRow}>
          <div className={classes.securityClientCol}>
            <div className={classes.securityClientItem}>
              <div className={classes.securityClientImgWrap}>
                <img src={img1} alt=''/>
              </div>
              <div className={classes.securityClientContent}>
                <h5
                  className={[
                    classes.securityClientItemTitle,
                    "font-18 fw-bold text-center",
                  ].join(" ")}
                >
                  {t("about_3_row_11")}
                </h5>
                <p
                  className={[
                    classes.securityClientItemText,
                    "text-center",
                  ].join(" ")}
                >
                  {t("about_3_row_12")}
                </p>
              </div>
            </div>
            <a className={classes.securityClientLink} href="https://client.xpinvestment.one/signup">
              {t("open_btn")}
            </a>
          </div>
          <div className={classes.securityClientCol}>
            <div className={classes.securityClientItem}>
              <div className={classes.securityClientImgWrap}>
                <img src={img2} alt=''/>
              </div>
              <div className={classes.securityClientContent}>
                <h5
                  className={[
                    classes.securityClientItemTitle,
                    "font-18 fw-bold text-center",
                  ].join(" ")}
                >
                  {t("about_3_row_21")}
                </h5>
                <p
                  className={[
                    classes.securityClientItemText,
                    "text-center",
                  ].join(" ")}
                >
                  {t("about_3_row_22")}
                </p>
              </div>
            </div>
            <a className={classes.securityClientLink} href="https://client.xpinvestment.one/signup">
              {t("open_btn")}
            </a>
          </div>
          <div className={classes.securityClientCol}>
            <div className={classes.securityClientItem}>
              <div className={classes.securityClientImgWrap}>
                <img src={img3} alt=''/>
              </div>
              <div className={classes.securityClientContent}>
                <h5
                  className={[
                    classes.securityClientItemTitle,
                    "font-18 fw-bold text-center",
                  ].join(" ")}
                >
                  {t("about_3_row_31")}
                </h5>
                <p
                  className={[
                    classes.securityClientItemText,
                    "text-center",
                  ].join(" ")}
                >
                  {t("about_3_row_32")}
                </p>
              </div>
            </div>
            <a className={classes.securityClientLink} href="https://client.xpinvestment.one/signup">
              {t("open_btn")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityClients;
