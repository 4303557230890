import React, { useEffect, useRef } from "react";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import Switch from "../../components/TradingSpaceComponents/Switch/Switch";
import Newsletters from "../../components/StartPage/Newsletters/Newsletters";
import TradingDemo from "../../components/TradingSpaceComponents/TradingDemo/TradingDemo";
import TradingDeposit from "../../components/TradingSpaceComponents/TradingDeposit/TradingDeposit";
import TradingSpace from "../../components/TradingSpaceComponents/TradingSpace/TradingSpace";
import TradingTable from "../../components/TradingSpaceComponents/TradingTable/TradingTable";
import classes from "./TradingSpacePage.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const TradingSpacePage = () => {
  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);
  const col4 = useRef(null);
  const col5 = useRef(null);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col3.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col3.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col4.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col4.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col5.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col5.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.trading}>
      <div className={classes.tradingHeader}>
        <Header />
      </div>
      <TradingSpace />
      <div className={[classes.col1, ""].join(" ")} ref={col1}>
        <TradingTable />
      </div>
      <div className={[classes.col2, ""].join(" ")} ref={col2}>
        <TradingDeposit />
      </div>
      <div className={[classes.col3, ""].join(" ")} ref={col3}>
        <TradingDemo />
      </div>
      <div className={[classes.col4, ""].join(" ")} ref={col4}>
        <Switch />
      </div>
      <div className={[classes.col5, ""].join(" ")} ref={col5}>
        <Newsletters />
      </div>

      <Footer />
    </div>
  );
};

export default TradingSpacePage;
