import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/TradingSpace/2/1.png";
import classes from "./TradingDeposit.module.scss";

import { useTranslation } from "react-i18next";

const TradingDeposit = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.deposit}>
      <div className={classes.depositBody}>
        <div className="container">
          <div className={classes.depositRow}>
            <div className={classes.depositRowLeft}>
              <div className={[classes.depositTitle, "font-45"].join(" ")}>
                {t("trading_3_1")}
              </div>
              <div
                className={[classes.depositRowLeftSubtitle, "font-20"].join(
                  " "
                )}
              >
                {t("trading_3_2")}
              </div>
              <ul className={classes.depositRowLeftList}>
                <li
                  className={[classes.depositRowLeftListItem, "font-20"].join(
                    " "
                  )}
                >
                  {t("trading_3_3")}
                </li>
                <li
                  className={[classes.depositRowLeftListItem, "font-20"].join(
                    " "
                  )}
                >
                  {t("trading_3_4")}
                </li>
                <li
                  className={[classes.depositRowLeftListItem, "font-20"].join(
                    " "
                  )}
                >
                  {t("trading_3_5")}
                </li>
              </ul>
              <div
                className={[classes.depositRowLeftText, "font-20"].join(" ")}
              >
                {t("trading_3_6")}
              </div>
              <div className={classes.depositRowLeftOpenBtn}>
                <Button> {t("open_btn")}</Button>
              </div>
            </div>
            <div className={classes.depositRowRight}>
              <div className={classes.depositRowRightImg}>
                <img src={img1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingDeposit;
