import React, { useEffect, useRef } from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import classes from "./MainPage.module.scss";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Welcome from "../../components/MainPageComponents/Welcome/Welcome";
import Trade from "../../components/MainPageComponents/Trade/Trade";
import Switch from "../../components/MainPageComponents/Switch/Switch";
import Get from "../../components/MainPageComponents/Get/Get";
import Stages from "../../components/MainPageComponents/Stages/Stages";
import Newsletters from "../../components/StartPage/Newsletters/Newsletters";
import Platform from "../../components/MainPageComponents/Platform/Platform";
import Open from "../../components/MainPageComponents/Open/Open";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const MainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);
  const col4 = useRef(null);
  const col5 = useRef(null);
  const col6 = useRef(null);
  const col7 = useRef(null);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col3.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col3.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col4.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col4.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col5.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col5.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col6.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col6.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col7.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col7.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, []);

  return (
    <div className={classes.main}>
      <div className={classes.mainHeader}>
        <Header />
      </div>

      <Welcome />
      <div className={[classes.col1, ""].join(" ")} ref={col1}>
        {" "}
        <Trade />
      </div>
      <div className={[classes.col2, ""].join(" ")} ref={col2}>
        <Switch />
      </div>
      <div className={[classes.col3, ""].join(" ")} ref={col3}>
        <Get />
      </div>
      <div className={[classes.col4, ""].join(" ")} ref={col4}>
        {" "}
        <Stages />
      </div>
      <div className={[classes.col5, ""].join(" ")} ref={col5}>
        <Platform />
      </div>
      <div className={[classes.col6, ""].join(" ")} ref={col6}>
        <Open />
      </div>
      <div className={[classes.col7, ""].join(" ")} ref={col7}>
        <Newsletters />
      </div>

      <Footer />
    </div>
  );
};

export default MainPage;
