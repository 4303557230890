import React, { useRef, useEffect } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Get.module.scss";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Get = () => {
  const mainRef = useRef(null);
  const ball1 = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(ball1.current, {
        scale: 1,
        rotate: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top top",
          end: "bottom 700px",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, []);
  return (
    <div className={classes.get}>
      <div className={classes.getBody}>
        <div className="container">
          <div className={[classes.getTitle, "font-45"].join(" ")}>
            {t("main_4_1")}
          </div>
          <div className={[classes.getSubtitle, "font-20"].join(" ")}>
            {t("main_4_2")}
          </div>
          <div className={classes.getTryBtn}>
            <Button>{t("try_btn")}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Get;
