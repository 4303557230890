import React from "react";
import classes from "./OpeningAnAccount.module.scss";
import num_01 from "../../../assets/img/StartPage/OpeningAnAccount/num_01.png";
import num_02 from "../../../assets/img/StartPage/OpeningAnAccount/num_02.png";
import num_03 from "../../../assets/img/StartPage/OpeningAnAccount/num_03.png";

import { useTranslation } from "react-i18next";

const OpeningAnAccount = () => {
  const { t } = useTranslation();

  const cards = [
    {
      id: 1,
      image: num_01,
      title: "start_3_item1_11",
      text: "start_3_item1_12",
    },
    {
      id: 2,
      image: num_02,
      title: "start_3_item1_21",
      text: "start_3_item1_22",
    },
    {
      id: 3,
      image: num_03,
      title: "start_3_item1_31",
      text: "start_3_item1_32",
    },
  ];

  return (
    <section className="container">
      <div className={classes.openingAnAccount}>
        <h2 className={[classes.title, "font-45"].join(" ")}>
          {t("start_3_1")}
        </h2>
        <h3 className={[classes.subtitle, "font-25"].join(" ")}>
          {t("start_3_2")}
        </h3>
        <div className={classes.cards}>
          {cards.map((card) => (
            <div className={classes.card} key={card.id}>
              <img
                className={[classes.cardImage, "no-select"].join(" ")}
                src={card.image}
                alt={card.title}
              />
              <h4 className={classes.cardTitle}>{t(card.title)}</h4>
              <p className={classes.cardText}>{t(card.text)}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OpeningAnAccount;
