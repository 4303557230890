import React from "react";
import classes from "./HowToDeposit.module.scss";
import num_01 from "../../../assets/img/AccountsPage/HowToDeposit/num_01.png";
import num_02 from "../../../assets/img/AccountsPage/HowToDeposit/num_02.png";
import num_03 from "../../../assets/img/AccountsPage/HowToDeposit/num_03.png";
import { Trans } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import "./HowToDeposit.scss";
import { useTranslation } from "react-i18next";

const HowToDeposit = () => {
  const { t } = useTranslation();

  const steps = [
    {
      id: 1,
      image: num_01,
      text: t("accounts_3_3"),
    },
    {
      id: 2,
      image: num_02,
      text: t("accounts_3_4"),
    },
    {
      id: 3,
      image: num_03,
      text: t("accounts_3_5"),
    },
  ];

  return (
    <section className="container">
      <div className={classes.howToDeposit}>
        <h2 className={[classes.title, "font-45"].join(" ")}>
          {t("accounts_3_1")}
        </h2>
        <p className={[classes.subtitle, "font-20"].join(" ")}>
          {t("accounts_3_2")}
        </p>
        <div className={classes.steps}>
          {steps.map((step) => (
            <div
              className={[classes.step, `step_${t("lang")}`].join(" ")}
              key={step.id}
            >
              <img className="no-select" src={step.image} alt={step.text} />
              <p className={classes.text}>
                <Trans>{t(step.text)}</Trans>
              </p>
            </div>
          ))}
        </div>
        <div className={classes.btn}>
          <Button>{t("make_btn")}</Button>
        </div>
      </div>
    </section>
  );
};

export default HowToDeposit;
