import React from "react";
import classes from "./TradingDemo.module.scss";
import img1 from "../../../assets/img/TradingSpace/3/1.png";
import img2 from "../../../assets/img/TradingSpace/3/2.png";
import img3 from "../../../assets/img/TradingSpace/3/3.png";

import { useTranslation } from "react-i18next";

const TradingDemo = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.demo}>
      <div className={classes.demoBody}>
        <div className="container">
          <div className={[classes.demoTitle, "font-45"].join(" ")}>
            {t("trading_4_1")}
          </div>
          <div className={[classes.demoSubtitle, "font-20"].join(" ")}>
            {t("trading_4_2")}
          </div>
          <div className={classes.demoRow}>
            <div className={classes.demoRowColumn}>
              <div className={classes.demoRowColumnItem}>
                <div className={classes.demoRowColumnItemImg}>
                  <img src={img1} alt="" />
                </div>
                <div
                  className={[classes.demoRowColumnItemTitle, "font-25"].join(
                    " "
                  )}
                >
                  {t("trading_4_11")}
                </div>
                <div
                  className={[classes.demoRowColumnItemSubtile, "font-20"].join(
                    " "
                  )}
                >
                  {t("trading_4_12")}
                </div>
              </div>
            </div>
            <div className={classes.demoRowColumn}>
              <div className={classes.demoRowColumnItem}>
                <div className={classes.demoRowColumnItemImg}>
                  <img src={img2} alt="" />
                </div>
                <div
                  className={[classes.demoRowColumnItemTitle, "font-25"].join(
                    " "
                  )}
                >
                  {t("trading_4_21")}
                </div>
                <div
                  className={[classes.demoRowColumnItemSubtile, "font-20"].join(
                    " "
                  )}
                >
                  {t("trading_4_22")}
                </div>
              </div>
            </div>
            <div className={classes.demoRowColumn}>
              <div className={classes.demoRowColumnItem}>
                <div className={classes.demoRowColumnItemImg}>
                  <img src={img3} alt="" />
                </div>
                <div
                  className={[classes.demoRowColumnItemTitle, "font-25"].join(
                    " "
                  )}
                >
                  {t("trading_4_31")}
                </div>
                <div
                  className={[classes.demoRowColumnItemSubtile, "font-20"].join(
                    " "
                  )}
                >
                  {t("trading_4_32")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingDemo;
