import React from "react";
import classes from './AccountsOverview.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/AccountsPage/AccountsOverview/image_01.png';
import image_02 from '../../../assets/img/AccountsPage/AccountsOverview/image_02.png';
import image_03 from '../../../assets/img/AccountsPage/AccountsOverview/image_03.png';
import image_04 from '../../../assets/img/AccountsPage/AccountsOverview/image_04.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";

const cards = [
    {
        image: image_01,
        title: 'Basic',
        list: [
            'acc_overview_li_01_01',
            'acc_overview_li_01_02',
            'acc_overview_li_01_03',
        ],
    },
    {
        image: image_02,
        title: 'Silver',
        list: [
            'acc_overview_li_02_01',
            'acc_overview_li_02_02',
            'acc_overview_li_02_03',
            'acc_overview_li_02_04',
            'acc_overview_li_02_05',
        ],
    },
    {
        image: image_03,
        title: 'Gold',
        list: [
            'acc_overview_li_03_01',
            'acc_overview_li_03_02',
            'acc_overview_li_03_03',
            'acc_overview_li_03_04',
            'acc_overview_li_03_05',
            'acc_overview_li_03_06',
            'acc_overview_li_03_07',
        ],
    },
    {
        image: image_04,
        title: 'V.I.P.',
        list: [
            'acc_overview_li_04_01',
            'acc_overview_li_04_02',
            'acc_overview_li_04_03',
            'acc_overview_li_04_04',
            'acc_overview_li_04_05',
            'acc_overview_li_04_06',
            'acc_overview_li_04_07',
            'acc_overview_li_04_08',
        ],
    },
];

const AccountsOverview = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.accountsOverview}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('acc_overview_title')} 
                </h2>
                <div className={classes.swiperWrap}>
                    <Swiper
                        modules={[Navigation]}
                        className="accountsOverviewSwiper no-select"
                        spaceBetween={20}
                        slidesPerView={1}
                        grabCursor
                        pagination={{ clickable: true }}
                        breakpoints={{
                            600: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            992: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1220: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                            1650: {
                                slidesPerView: 4,
                                spaceBetween: 70,
                            },
                        }}
                    >
                        {cards.map((card, index) =>
                            <SwiperSlide key={index} className="accountsOverviewSwiperSlide">
                                <div className={classes.card}>
                                    <img className={classes.cardImage} src={card.image} alt=''/>
                                    <h4 className={classes.cardTitle}>
                                        {card.title}
                                    </h4>
                                    <ul>
                                        {card.list.map((li, liIndex) =>
                                            <li key={liIndex}>
                                                {t(li)}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default AccountsOverview;
