import React from "react";
import classes from './Activities.module.scss';
import bg from '../../../assets/img/VattenfallPage/activities_arrow_bg.svg';

const rows = [
    [
        'Key figures',
        '2022',
        '2021',
    ],
    [
        'Installed electricity capacity (MW)',
        '28,876',
        '29,152',
    ],
    [
        'Production electricity (TWh)',
        '108,9',
        '111,4',
    ],
    [
        'Net sales (MSEK)',
        '239,644',
        '180,119',
    ],
    [
        'EBITDA (MSEK)',
        '30,513',
        '75,790',
    ],
    [
        'EBIT(MSEK)',
        '12,645',
        '60,271',
    ],
    [
        'Underlying EBIT (MSEK)',
        '37,313',
        '31,181',
    ],
    [
        'Profit for the period (MSEK)',
        '21',
        '48,013',
    ],
    [
        'FFO (MSEK)',
        '42,194',
        '46,096',
    ],
    [
        'Adjusted net debt (MSEK)',
        '76,766',
        '26,924',
    ],
    [
        'Return on capital employed (8%)',
        '4,2',
        '22,2',
    ],
    [
        'FFO/Adj. net debt (22%-27%)',
        '55,0',
        '171,2',
    ],
    [
        'Dividend (40%-70% of profit after tax, MSEK)',
        '2000',
        '23,414',
    ],
];

const nums = [
    {
        title: '19,638',
        text: 'Employees',
    },
    {
        title: '7.5 million',
        text: 'Electricity customers.',
    },
    {
        title: '2.0 million',
        text: 'Heat Customers',
    },
    {
        title: '2.3 million',
        text: 'Gas customers',
    },
    {
        title: '1.0 million',
        text: 'Electricity network customers',
    },
];

const steps = [
    'Upstream',
    'Production',
    'Transmission',
    'Distribution',
    'Trading',
    'Retail',
    'Services',
];

const Activities = () => {
    return (
        <section className="mt container">
            <div className={classes.activities}>
                <div className={classes.header}>
                    <div className={[classes.chain, 'font-20'].join(' ')}>
                        <h2 className={classes.title}>
                            Activities in the value chain.
                        </h2>
                        <ul>
                            <li>
                                Active
                            </li>
                            <li>
                                Inactive.
                            </li>
                        </ul>
                    </div>
                    <div className={classes.steps}>
                        {steps.map((step, index) =>
                            <div key={index} className={classes.step}>
                                <img className={[classes.bg, 'no-select'].join(' ')} src={bg} alt=''/>
                                <span>
                                    {step}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes.content}>
                    <div className={classes.nums}>
                        {nums.map((num, index) =>
                            <div className={classes.num} key={index}>
                                <h4 className={[classes.numTitle, 'font-45'].join(' ')}>
                                    {num.title}
                                </h4>
                                <p className={[classes.numText, 'font-20'].join(' ')}>
                                    {num.text}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className={classes.tableContainer}>
                        <div className={classes.tableWrap}>
                            <table>
                                <tbody>
                                    {rows.map((row, rowIndex) =>
                                        <tr key={rowIndex}>
                                            {row.map((col, colIndex) =>
                                                <td key={colIndex}>
                                                    {col}
                                                </td>
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Activities;
