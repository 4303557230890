import React, { useRef, useEffect } from "react";
import classes from "./Banner.module.scss";
import image from "../../../assets/img/AccountsPage/Banner/image.png";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Banner = () => { 
  const firstRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.to(firstRef.current, {
        y: 0,
        opacity: 1,
        scale: 1,
        delay: 0.5,
        duration: 1.5,
      });
    }
  }, []);
  return (
    <section className="container">
      <div className={classes.banner}>
        <div className={classes.content}>
          <h1 className={[classes.title, "font-45-banner"].join(" ")}>
            {t("accounts_1_1")}
          </h1>
          <p className={[classes.text, "font-25"].join(" ")}>
            {t("accounts_1_2")}
          </p>
          <div className={classes.btn}>
            <Button>{t("start_btn")}</Button>
          </div>
        </div>
        <img
          ref={firstRef}
          className="no-select"
          src={image}
          alt="Account Types and Instruments Diversity at XPinvestment"
        />
      </div>
    </section>
  );
};

export default Banner;
