import React from "react";
import { useState } from "react";
import classes from "./WhatAreForex.module.scss";
import arrow from "../../../assets/img/LearnPage/show_arrow.png";
import Button from "../../GlobalComponents/Button/Button";

import { useTranslation } from "react-i18next";

const WhatAreForex = () => {
  const { t } = useTranslation();

  const [hide, setHide] = useState(true);
  return (
    <section className="container">
      <div className={classes.whatAreFinancial}>
        <h2 className={[classes.title, "font-45"].join(" ")}>
          {t("learn_31")}
        </h2>
        <div className={[classes.content, "font-20"].join(" ")}>
          <div className={classes.col}>
            <p>{t("learn_3_left1")}</p>
            <p>{t("learn_3_left2")}</p>
            <div className={`${hide && classes.hide}`}>
              <p>{t("learn_3_left3")}</p>
            </div>
          </div>
          <div className={`${hide && classes.hide}`}>
            <div className={classes.col}>
              <p>{t("learn_3_right1")}</p>
              <p>{t("learn_3_right2")}</p>
              <div className={classes.btn}>
                <Button>{t("open_btn")}</Button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${classes.showBtn} ${
            hide ? classes.showMore : classes.showLess
          }`}
          onClick={() => setHide((value) => !value)}
        >
          <img src={arrow} alt="Show text" />
          {hide ? "Read more" : "Hide text"}
        </div>
        <div className={classes.bottomBtn}>
          <Button>{t("open_btn")}</Button>
        </div>
      </div>
    </section>
  );
};

export default WhatAreForex;
