import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./DontMiss.module.scss";
import image from "../../../assets/img/StartPage/dont_miss.png";
import { useTranslation } from "react-i18next";

const DontMiss = () => {
  const { t } = useTranslation();
  return (
    <section className="container">
      <div className={classes.dontMiss}>
        <div className={classes.content}>
          <h2 className={[classes.title, "font-45"].join(" ")}>
            {t("start_5_1")}
          </h2>
          <div className={[classes.text, "font-20"].join(" ")}>
            <p>{t("start_5_2")}</p>
            <p>{t("start_5_3")}</p>
          </div>
          <div className={classes.btn}>
            <Button>{t("create_btn")}</Button>
          </div>
        </div>
        <img
          className="no-select"
          src={image}
          alt="Dont miss out on crypto trading opportunities with XPinvestment"
        />
      </div>
    </section>
  );
};

export default DontMiss;
