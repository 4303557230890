import React from "react";
import classes from '../PowerRevolution/PowerRevolution.module.scss';

const PowerGeneration = () => {
    return (
        <section className="mt container">
            <div className={classes.container}>
                <div className={classes.col}>
                    <h2 className={[classes.title, 'font-45'].join(' ')}>
                        Power Generation
                    </h2>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            Vattenfall's Power Generation division includes the Generation and Markets business units. This segment consists of various operations, such as hydroelectric and nuclear power generation and maintenance services. 
                        </p>
                        <p>
                            It also includes optimization and trading operations that serve specific large business customers. Through these various components, Vattenfall's Power Generation division plays a critical role in meeting energy demands, ensuring operational efficiency, and providing tailored solutions to key clients.
                        </p>
                    </div>
                </div>
                <div className={classes.col}>
                    <h2 className={[classes.title, 'font-45'].join(' ')}>
                        Endless wind power
                    </h2>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            The Wind business area at Vattenfall is in charge of the development, construction, and operation of the company's wind farms. This business unit oversees the implementation of large-scale and decentralized solar power projects, as well as the integration of battery systems, in addition to wind energy initiatives. 
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PowerGeneration;
