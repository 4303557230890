import React from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <div className="container">
      <div className={classes.footer}>
        <div className={classes.col1}>
          <div className={classes.disclaimer}>{t("footer_1_1")}</div>
          <div className={classes.about}>{t("footer_1_2")}</div>
          <div className={classes.info}>
            {t("footer_1_3")}
          </div>
        </div>
        <div className={classes.col2}>
          <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank" className={classes.item}>{t("footer_2_1")}</a>
          <a href="/docs/PRIVACY_POLICY.pdf" target="_blank" className={classes.item}>{t("footer_2_2")}</a>
          <a href="/docs/AML_POLICY.pdf" target="_blank" className={classes.item}>{t("footer_2_3")}</a>
        </div>
        <div className={classes.col3}>
          <div>{t("footer_3_1")}</div>
          <div className={classes.par}>
            {t("footer_3_2")}
            <br /> <a href="tel:+441733833193">+441733833193</a>
            <br /> <a href={`mailto:support@${hostname}`}>support@{hostname}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
