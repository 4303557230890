import React from "react";
import { useState, useEffect } from "react";
import { useInput } from "../../../hooks/useInput";
import Input from "../../GlobalComponents/Input/Input";
import classes from "./Open.module.scss";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import Button from "../../GlobalComponents/Button/Button";
import "./Open.scss";
import { useTranslation } from "react-i18next";

const Open = () => {
  const { t } = useTranslation();
  const [policy, setPolicy] = useState(false);
  const [analysis, setAnalysis] = useState(false);
  const [typeAccount, setTypeAccount] = useState(t("main_8_right_6"));
  const [saveTypeAccount, setSaveTypeAccount] = useState("main_8_right_6");
  const [typeAccountVisible, setTypeAccountVisible] = useState(false);
  const [typeAccountNumber, setTypeAccountNumber] = useState(0);
  const [typeAccountError, setTypeAccountError] = useState(false);

  useEffect(() => {
    setTypeAccount(t(`${saveTypeAccount}`));
    // eslint-disable-next-line
  }, [t("lang")]);

  const name = useInput("", { isEmpty: true, minLength: 3 });
  const surname = useInput("", { isEmpty: true, minLength: 3 });
  const email = useInput("", { isEmpty: true, minLength: 1, isEmail: true });
  const phone = useInput("", { isEmpty: true, minLength: 6, phone: false });

  const onSend = async () => {
    if (
      !name.inputValid ||
      !email.inputValid ||
      !phone.inputValid ||
      !surname.inputValid ||
      typeAccountNumber === 0
    ) {
      name.setDirty(true);
      email.setDirty(true);
      phone.setDirty(true);
      surname.setDirty(true);
      setTypeAccountError(typeAccountNumber === 0);
    } 
  };

  return (
    <div className={classes.open}>
      <div className={classes.openBody}>
        <div className="container">
          <div className={classes.openRow}>
            <div className={classes.openRowLeft}>
              <div className={[classes.openRowLeftTitle, "font-45"].join(" ")}>
                {t("main_7_left_1")}
              </div>
              <div
                className={[classes.openRowLeftSubtitle, "font-25"].join(" ")}
              >
                {t("main_7_left_2")}
              </div>
              <div className={[classes.openRowLeftText, "font-20"].join(" ")}>
                {t("main_7_left_3")}
              </div>
              <div
                className={[classes.openRowLeftSubtitle, "font-25"].join(" ")}
              >
                {t("main_7_left_4")}
              </div>
              <div className={[classes.openRowLeftText, "font-20"].join(" ")}>
                {t("main_7_left_5")}
              </div>
              <div
                className={[classes.openRowLeftSubtitle, "font-25"].join(" ")}
              >
                {t("main_7_left_6")}
              </div>
              <div className={[classes.openRowLeftText, "font-20"].join(" ")}>
                {t("main_7_left_7")}
              </div>
            </div>
            <div className={classes.openRowRight}>
              <div className={[classes.openRowRightTitle, "font-45"].join(" ")}>
                {t("main_8_right_1")}
              </div>
              <div className={classes.openRowRightTop}>
                <div className={classes.openRowRightTopLeft}>
                  {/* <input type="text" placeholder="First name" /> */}
                  {/* <Input type="text" placeholder="First name" /> */}
                  <Input
                    type="text"
                    placeholder={t("main_8_right_2")}
                    inputValues={name}
                  />
                </div>
                <div className={classes.openRowRightTopRight}>
                  <Input
                    type="text"
                    placeholder={t("main_8_right_3")}
                    inputValues={surname}
                  />
                </div>
              </div>
              <div className={classes.openRowRightCountry}>
                <CountryDropdown
                  id="UNIQUE_ID"
                  className="YOUR_CSS_CLASS"
                  preferredCountries={["gb", "us"]}
                  value=""
                  handleChange={(e) => console.log(e.target.value)}
                ></CountryDropdown>
              </div>
              <div className={classes.openRowRightCenter}>
                <div className={classes.openRowRightCenterLeft}>
                  <Input
                    type="text"
                    placeholder={t("main_8_right_4")}
                    eyeHidden={true}
                    inputValues={phone}
                  />
                </div>
                <div className={classes.openRowRightCenterRight}>
                  <Input
                    type="text"
                    placeholder={t("main_8_right_5")}
                    inputValues={email}
                  />
                </div>
              </div>
              <div
                className={
                  typeAccountVisible
                    ? [classes.openRowRightTypeAccount, classes.active].join(
                        " "
                      )
                    : classes.openRowRightTypeAccount
                }
              >
                <div
                  className={
                    typeAccountError
                      ? [
                          classes.openRowRightTypeAccountTitle,
                          classes.error,
                        ].join(" ")
                      : classes.openRowRightTypeAccountTitle
                  }
                  onClick={() => {
                    setTypeAccountVisible(!typeAccountVisible);
                  }}
                >
                  {typeAccount}
                  <div className={classes.openRowRightTypeAccountContent}>
                    <div
                      className={
                        typeAccountNumber === 1
                          ? [
                              classes.openRowRightTypeAccountContentColumn,
                              classes.active,
                            ].join(" ")
                          : classes.openRowRightTypeAccountContentColumn
                      }
                      onClick={() => {
                        setTypeAccount(t("main_8_right_7"));
                        setTypeAccountNumber(1);
                        setTypeAccountVisible(false);
                        setTypeAccountError(false);
                        setSaveTypeAccount("main_8_right_7");
                      }}
                    >
                      {t("main_8_right_7")}
                    </div>
                    <div
                      className={
                        typeAccountNumber === 2
                          ? [
                              classes.openRowRightTypeAccountContentColumn,
                              classes.active,
                            ].join(" ")
                          : classes.openRowRightTypeAccountContentColumn
                      }
                      onClick={() => {
                        setTypeAccount(t("main_8_right_8"));
                        setTypeAccountNumber(2);
                        setTypeAccountVisible(false);
                        setTypeAccountError(false);
                        setSaveTypeAccount("main_8_right_8");
                      }}
                    >
                      {t("main_8_right_8")}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  policy
                    ? [classes.openRowRightPolicy, classes.active].join(" ")
                    : classes.openRowRightPolicy
                }
                onClick={() => {
                  setPolicy(!policy);
                }}
              >
                <span></span>
                {t("main_8_right_9")}
              </div>
              <div
                className={
                  analysis
                    ? [classes.openRowRightAnalysis, classes.active].join(" ")
                    : classes.openRowRightAnalysis
                }
                onClick={() => {
                  setAnalysis(!analysis);
                }}
              >
                <span></span>
                {t("main_8_right_10")}
              </div>
              <div className={classes.openRowRightBtn}>
                <Button
                  onClick={() => {
                    onSend();
                  }}
                >
                  {t("submit_btn")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Open;
