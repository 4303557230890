import React from "react";
import classes from "./TopPerks.module.scss";

import { useTranslation } from "react-i18next";

const TopPerks = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.topPerksContainer}>
      <div className="container">
        <h3 className={[classes.businessProfilesTitle, "font-45"].join(" ")}>
          {t("about_5_1")}
        </h3>
        <div className={classes.topPerksRow}>
          <div className={classes.topPerksCol}>
            <p className={[classes.topPerksItemTitle, "font-25"].join(" ")}>
              {t("about_5_left1")}
            </p>
            <p className={[classes.topPerksItemText, "font-20"].join(" ")}>
              USD, EUR, CNY, GOLD <br />
              {t("about_5_left2")}
            </p>
          </div>
          <div className={classes.topPerksCol}>
            <p className={[classes.topPerksItemTitle, "font-25"].join(" ")}>
              {t("about_5_right1")}
            </p>
            <p className={[classes.topPerksItemText, "font-20"].join(" ")}>
              {t("about_5_right2")}
            </p>
          </div>
          <div className={classes.topPerksCol}>
            <p className={[classes.topPerksItemTitle, "font-25"].join(" ")}>
              {t("about_5_left3")}
            </p>
            <p className={[classes.topPerksItemText, "font-20"].join(" ")}>
              {t("about_5_left4")}
            </p>
          </div>
          <div className={classes.topPerksCol}>
            <p className={[classes.topPerksItemTitle, "font-25"].join(" ")}>
              {t("about_5_right3")}
            </p>
            <p className={[classes.topPerksItemText, "font-20"].join(" ")}>
              {t("about_5_right4")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPerks;
