import React, { useRef, useEffect } from "react";
import classes from "./Welcome.module.scss";
import img1 from "../../../assets/img/MainPage/1/content.png";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Welcome = () => {
  const firstRef = useRef(null);
  const welcomeRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.to(firstRef.current, {
        y: 0,
        opacity: 1,
        scale: 1,
        delay: 0.5,
        duration: 1.5,
      });
      gsap.timeline().to(welcomeRef.current, {
        y: 0,
        scrollTrigger: {
          trigger: welcomeRef.current,
          //  start: "top top",
          //  end: "bottom -300px",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, []);
  return (
    <div className={classes.welcome} ref={welcomeRef}>
      <div className={classes.welcomeBody}>
        <div className="container">
          <div className={[classes.welcomeTitle, "font-45"].join(" ")}>
            {t("main_1_1")}
          </div>
          <div className={[classes.welcomeSubtitle, "font-25"].join(" ")}>
            {t("main_1_2")}
          </div>
          <div className={classes.welcomeStartBtn}>
            <Button>{t("start_btn")}</Button>
          </div>
        </div>
        <div className={classes.welcomeBodyImg} ref={firstRef}>
          <img src={img1} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
