export function validateEmail(email) {
    var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return !re.test(String(email).toLowerCase());
};

export const returnFirstError = (array) => {
    let newA = [];
    // eslint-disable-next-line
    array.map((i) => {
        if (i.valid) {
            newA.push(i.label)
        }
    })
    return newA[0] ? newA[0] : "";
};

export function isValid(p) {
    // eslint-disable-next-line
    var phoneRe = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    var digits = p.replace(/\D/g, "");
    return phoneRe.test(digits);
};