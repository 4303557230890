import React from "react";
import classes from './ActivelyLeading.module.scss';
import image_01 from '../../../assets/img/VattenfallPage/ActivelyLeading/image_01.png';
import image_02 from '../../../assets/img/VattenfallPage/ActivelyLeading/image_02.png';
import image_03 from '../../../assets/img/VattenfallPage/ActivelyLeading/image_03.png';
import image_04 from '../../../assets/img/VattenfallPage/ActivelyLeading/image_04.png';
import image_05 from '../../../assets/img/VattenfallPage/ActivelyLeading/image_05.png';
import image_06 from '../../../assets/img/VattenfallPage/ActivelyLeading/image_06.png';

const images = [
    image_01,
    image_02,
    image_03,
    image_04,
    image_05,
    image_06,
];

const ActivelyLeading = () => {
    return (
        <section className="mt container">
            <div className={classes.activelyLeading}>
                <p className={[classes.text, 'font-20'].join(' ')}> 
                    We are actively leading the charge toward a more sustainable energy system and focusing on increasing renewable energy production. We are providing climate-smart energy solutions to our valued customers.
                </p>
                <div className={classes.content}>
                    {images.map((img, index) =>
                        <img className={classes.image} key={index} src={img} alt=''/>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ActivelyLeading;
