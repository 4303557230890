import React, { useRef, useState } from "react";
import classes from "./Switch.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Switch.scss";
import "swiper/css";
import { useTranslation } from "react-i18next";

import img from "../../../assets/img/MainPage/3/left.png";
import "swiper/swiper.min.css";

import { Navigation, Pagination } from "swiper";
import Button from "../../GlobalComponents/Button/Button";

import img1 from "../../../assets/img/TradingSpace/4/1.png";
import img2 from "../../../assets/img/TradingSpace/4/2.png";
import img3 from "../../../assets/img/TradingSpace/4/3.png";
import img4 from "../../../assets/img/TradingSpace/4/4.png";
import img5 from "../../../assets/img/TradingSpace/4/5.png";
import img6 from "../../../assets/img/TradingSpace/4/6.png";
import img7 from "../../../assets/img/TradingSpace/4/7.png";

const Switch = () => {
  const { t } = useTranslation();
  const [my_swiper, set_my_swiper] = useState({});
  const myRef = useRef(null);

  return (
    <div className={classes.eight}>
      <div className="container">
        <div className={[classes.eightTitle, "font-45"].join(" ")}>
          {t("trading_5_1")}
        </div>

        <div className={classes.eightSlider} id="myFirstSlider12">
          <Swiper
            modules={[Navigation, Pagination]}
            pagination={{ clickable: true, el: myRef.current }}
            spaceBetween={0}
            slidesPerView={3}
            centeredSlides={true}
            loop={true}
            breakpoints={{
              1351: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
                centeredSlides: false,
              },
              100: {
                slidesPerView: 1,
              },
            }}
            onInit={(ev) => {
              set_my_swiper(ev);
            }}
          >
            <SwiperSlide>
              <div className={classes.sliderColumn}>
                <div className={classes.sliderColumnItem}>
                  <div className={classes.sliderColumnItemImg}>
                    <img src={img1} alt="" />
                  </div>
                  <div
                    className={[classes.sliderColumnItemTitle, "font-25"].join(
                      " "
                    )}
                  >
                    {t("trading_5_11")}
                  </div>
                  <div
                    className={[
                      classes.sliderColumnItemSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("trading_5_12")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={classes.sliderColumn}>
                <div className={classes.sliderColumnItem}>
                  <div className={classes.sliderColumnItemImg}>
                    <img src={img2} alt="" />
                  </div>
                  <div
                    className={[classes.sliderColumnItemTitle, "font-25"].join(
                      " "
                    )}
                  >
                    {t("trading_5_21")}
                  </div>
                  <div
                    className={[
                      classes.sliderColumnItemSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("trading_5_22")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={classes.sliderColumn}>
                <div className={classes.sliderColumnItem}>
                  <div className={classes.sliderColumnItemImg}>
                    <img src={img3} alt="" />
                  </div>
                  <div
                    className={[classes.sliderColumnItemTitle, "font-25"].join(
                      " "
                    )}
                  >
                    {t("trading_5_31")}
                  </div>
                  <div
                    className={[
                      classes.sliderColumnItemSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("trading_5_32")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={classes.sliderColumn}>
                <div className={classes.sliderColumnItem}>
                  <div className={classes.sliderColumnItemImg}>
                    <img src={img4} alt="" />
                  </div>
                  <div
                    className={[classes.sliderColumnItemTitle, "font-25"].join(
                      " "
                    )}
                  >
                    {t("trading_5_41")}
                  </div>
                  <div
                    className={[
                      classes.sliderColumnItemSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("trading_5_42")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={classes.sliderColumn}>
                <div className={classes.sliderColumnItem}>
                  <div className={classes.sliderColumnItemImg}>
                    <img src={img5} alt="" />
                  </div>
                  <div
                    className={[classes.sliderColumnItemTitle, "font-25"].join(
                      " "
                    )}
                  >
                    {t("trading_5_51")}
                  </div>
                  <div
                    className={[
                      classes.sliderColumnItemSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("trading_5_52")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={classes.sliderColumn}>
                <div className={classes.sliderColumnItem}>
                  <div className={classes.sliderColumnItemImg}>
                    <img src={img6} alt="" />
                  </div>
                  <div
                    className={[classes.sliderColumnItemTitle, "font-25"].join(
                      " "
                    )}
                  >
                    {t("trading_5_61")}
                  </div>
                  <div
                    className={[
                      classes.sliderColumnItemSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("trading_5_62")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={classes.sliderColumn}>
                <div className={classes.sliderColumnItem}>
                  <div className={classes.sliderColumnItemImg}>
                    <img src={img7} alt="" />
                  </div>
                  <div
                    className={[classes.sliderColumnItemTitle, "font-25"].join(
                      " "
                    )}
                  >
                    {t("trading_5_71")}
                  </div>
                  <div
                    className={[
                      classes.sliderColumnItemSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("trading_5_72")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className={classes.eightArrows}>
            <div className={classes.eightArrowsRow}>
              <div
                className={classes.eightArrowsRowLeft}
                onClick={() => {
                  my_swiper.slidePrev();
                }}
              >
                <img src={img} alt="" />
              </div>
              <div
                className={classes.eightArrowsRowRight}
                onClick={() => {
                  my_swiper.slideNext();
                }}
              >
                <img src={img} alt="" />
              </div>
            </div>
          </div>
          <div className="swiper-pagination3" ref={myRef}></div>
        </div>
        <div className={classes.eightBtn}>
          <Button>{t("open_btn")}</Button>
        </div>
      </div>
    </div>
  );
};

export default Switch;
