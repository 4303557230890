import React from "react";
import classes from '../PowerRevolution/PowerRevolution.module.scss';

const AboutUs = () => {
    return (
        <section className="mt container">
            <div className={classes.container}>
                <div className={classes.col}>
                    <h2 className={[classes.title, 'font-45'].join(' ')}>
                        About us
                    </h2>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            Vattenfall is a prominent energy company in Europe, having pioneered the electrification of industries, supplied energy to countless homes, and transformed our lifestyles for over a century through innovation and collaboration. Our current goal is to achieve fossil-free living within a single generation. To that end, we are actively leading the transition to a more sustainable energy system by increasing renewable energy generation and providing climate-smart energy solutions to our valued customers.
                        </p>
                    </div>
                </div>
                <div className={classes.col}>
                    <h2 className={[classes.title, 'font-45'].join(' ')}>
                        Contact us:
                    </h2>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            Vattenfall AB
                        </p>
                        <p>
                            Tel.+46 (0)8 739 50 00
                        </p>
                        <p>
                            Fax.+46 (0)8 17 85 06
                        </p>
                        <p>
                            SE-169 92 Stockholm
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
