import React, { useRef, useEffect } from "react";
import classes from "./TradingSpace.module.scss";
import img1 from "../../../assets/img/TradingSpace/1/2.png";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const TradingSpace = () => {
  const firstRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.to(firstRef.current, {
        y: 0,
        opacity: 1,
        scale: 1,
        delay: 0.5,
        duration: 1.5,
      });
    }
  }, []);
  return (
    <div className={classes.trading}>
      <div className={classes.tradingBody}>
        <div className="container">
          <div className={classes.tradingRow}>
            <div className={classes.tradingRowLeft}>
              <div
                className={[classes.tradingRowLeftTitle, "font-45"].join(" ")}
              >
                {t("trading_1_1")}
              </div>
              <div
                className={[classes.tradingRowLeftSubtitle, "font-20"].join(
                  " "
                )}
              >
                {t("trading_1_2")}
              </div>
              <div className={classes.tradingRowLeftOpenBtn}>
                <Button>{t("open_btn")}</Button>
              </div>
            </div>
            <div className={classes.tradingRowRight}>
              <div className={classes.tradingRowRightImg} ref={firstRef}>
                <img src={img1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingSpace;
