import React, { useEffect, useRef } from "react";
import classes from "./AccountsPage.module.scss";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/AccountsPage/Banner/Banner";
import HowToDeposit from "../../components/AccountsPage/HowToDeposit/HowToDeposit";
import ClientMoney from "../../components/AccountsPage/ClientMoney/ClientMoney";
import Tabs from "../../components/AccountsPage/Tabs/Tabs";
import Newsletters from "../../components/StartPage/Newsletters/Newsletters";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AccountsOverview from "../../components/AccountsPage/AccountsOverview/AccountsOverview";
gsap.registerPlugin(ScrollTrigger);

const AccountsPage = () => {
  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);
  const col4 = useRef(null);
  const col5 = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col3.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col3.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col4.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col4.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col5.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col5.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, []);
  return (
    <div className={["ovf-hidden", classes.page].join(" ")}>
      <Header />
      <Banner />
      <div className={[classes.col1, ""].join(" ")} ref={col1}>
        {/* <AccountTypes /> */}
      </div>
      <AccountsOverview />
      <div className={[classes.col2, ""].join(" ")} ref={col2}>
        <HowToDeposit />
      </div>
      <div className={[classes.col3, ""].join(" ")} ref={col3}>
        <ClientMoney />
      </div>
      <div className={[classes.col4, ""].join(" ")} ref={col4}>
        <Tabs />
      </div>
      <div className={[classes.col5, ""].join(" ")} ref={col5}>
        <Newsletters />
      </div>

      <Footer />
    </div>
  );
};

export default AccountsPage;
