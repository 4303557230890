import React, { useRef, useEffect, useState } from "react";
import classes from "./Faq.module.scss";
import $ from "jquery";

import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  const tab01Ref = useRef(null);
  const tab02Ref = useRef(null);
  const tab03Ref = useRef(null);
  const tab04Ref = useRef(null);
  const tab05Ref = useRef(null);
  const [tab01Active, setTab01Active] = useState(false);
  const [tab02Active, setTab02Active] = useState(false);
  const [tab03Active, setTab03Active] = useState(false);
  const [tab04Active, setTab04Active] = useState(false);
  const [tab05Active, setTab05Active] = useState(false);
  useEffect(() => {
    $(tab01Ref.current).slideUp(0);
    $(tab02Ref.current).slideUp(0);
    $(tab03Ref.current).slideUp(0);
    $(tab04Ref.current).slideUp(0);
    $(tab05Ref.current).slideUp(0);
  }, []);
  return (
    <section className="container">
      <div className={classes.faq}>
        <div className={[classes.title, "font-45"].join(" ")}>
          {t("learn_9_1")}
        </div>
        <div className={classes.spoilers}>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab01Ref.current).slideToggle(400);
              setTab01Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>{t("learn_9_item11")}</h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab01Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab01Ref} className={classes.tabContent}>
              {t("learn_9_item12")}
            </p>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab02Ref.current).slideToggle(400);
              setTab02Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>{t("learn_9_item21")}</h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab02Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab02Ref} className={classes.tabContent}>
              {t("learn_9_item22")}
            </p>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab03Ref.current).slideToggle(400);
              setTab03Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>{t("learn_9_item31")}</h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab03Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab03Ref} className={classes.tabContent}>
              {t("learn_9_item32")}
            </p>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab04Ref.current).slideToggle(400);
              setTab04Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>{t("learn_9_item41")}</h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab04Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab04Ref} className={classes.tabContent}>
              {t("learn_9_item42")}
            </p>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab05Ref.current).slideToggle(400);
              setTab05Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>{t("learn_9_item51")}</h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab05Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab05Ref} className={classes.tabContent}>
              {t("learn_9_item52")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
