import React, { useRef, useEffect } from "react";
import classes from "./Trade.module.scss";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Trade = () => {
  const mainRef = useRef(null);
  const ball1 = useRef(null);
  const ball2 = useRef(null);
  const ball3 = useRef(null);
  const ball4 = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(ball1.current, {
        scale: 1,
        rotate: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top top",
          end: "bottom 700px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(ball2.current, {
        scale: 1,
        rotate: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top top",
          end: "bottom 700px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(ball3.current, {
        scale: 1,
        rotate: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top top",
          end: "bottom 700px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(ball4.current, {
        scale: 1,
        rotate: 0,
        scrollTrigger: {
          trigger: mainRef.current,
          start: "top top",
          end: "bottom 700px",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, []);
  return (
    <div className={classes.trade} ref={mainRef}>
      <div className={classes.tradeBody}>
        <div className="container1">
          <div className={classes.tradeWrapper}>
            <div className={[classes.tradeTitle, "font-45"].join(" ")}>
              {t("main_2_1")}
            </div>
            <div className={[classes.tradeItem, "font-20"].join(" ")}>
              <span> {t("main_2_2")}</span>
            </div>
            <div className={[classes.tradeItem, "font-20"].join(" ")}>
              <span> {t("main_2_3")}</span>
            </div>
            <div className={[classes.tradeItem, "font-20"].join(" ")}>
              <span>{t("main_2_4")}</span>
            </div>
            <div className={[classes.tradeItem, "font-20"].join(" ")}>
              <span> {t("main_2_5")}</span>
            </div>
            <div className={classes.tradeImg1} ref={ball1}></div>
            <div className={classes.tradeImg2} ref={ball2}></div>
            <div className={classes.tradeImg3} ref={ball3}></div>
            <div className={classes.tradeImg4} ref={ball4}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trade;
