import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Banner.module.scss";
import gif from "../../../assets/img/LearnPage/banner.gif";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <section className={classes.banner}>
      <div className={["container", classes.container].join(" ")}>
        <div className={classes.content}>
          <h1 className={[classes.title, "font-45-banner"].join(" ")}>
            {t("learn_11")}
          </h1>
          <p className={[classes.text, "font-20"].join(" ")}>{t("learn_12")}</p>
          <div className={classes.btn}>
            <Button>{t("open_btn")}</Button>
          </div>
        </div>
        <img
          className="no-select"
          src={gif}
          alt="Learn to Trade with XPinvestment!"
        />
      </div>
    </section>
  );
};

export default Banner;
