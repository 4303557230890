import React from "react";
import classes from './Banner.module.scss';
import image from '../../../assets/img/VattenfallPage/banner.png';
import Button from '../../GlobalComponents/Button/Button';

const Banner = () => {
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={[classes.title, 'font-45-banner'].join(' ')}>
                        Vattenfall is a new word in the sustainable energy system
                    </h1>
                    <p className={[classes.text, 'font-20'].join(' ')}>
                        Vattenfall has emerged as a dominant force in the European energy sector for over a century. Today, our primary goal is to facilitate a future in which living without fossil fuels becomes a reality within a single generation.
                    </p>
                    <div className={classes.btn}>
                        <Button>
                            Open Account
                        </Button>
                    </div>
                </div>
                <img className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default Banner;
