import React, { useEffect, useRef } from "react";
import classes from "./LearnPage.module.scss";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/LearnPage/Banner/Banner";
import WhatAreFinancial from "../../components/LearnPage/WhatAreFinancial/WhatAreFinancial";
import WhatAreForex from "../../components/LearnPage/WhatAreForex/WhatAreForex";
import HowDoes from "../../components/LearnPage/HowDoes/HowDoes";
import WhatIsTraded from "../../components/LearnPage/WhatIsTraded/WhatIsTraded";
import HowDoTraders from "../../components/LearnPage/HowDoTraders/HowDoTraders";
import HowToOpen from "../../components/LearnPage/HowToOpen/HowToOpen";
import Faq from "../../components/LearnPage/Faq/Faq";
import Newsletters from "../../components/StartPage/Newsletters/Newsletters";
import HowToBuy from "../../components/LearnPage/HowToBuy/HowToBuy";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const LearnPage = () => {
  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);
  const col4 = useRef(null);
  const col5 = useRef(null);
  const col6 = useRef(null);
  const col7 = useRef(null);
  const col8 = useRef(null);
  const col9 = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col3.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col3.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col4.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col4.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col5.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col5.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col6.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col6.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col7.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col7.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col8.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col8.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col9.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col9.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, []);
  return (
    <div className="ovf-hidden">
      <div className={classes.bgTop}>
        <Header />
        <Banner />
      </div>
      <div className={classes.bgPage}>
        <div className={[classes.col1, ""].join(" ")} ref={col1}>
          {" "}
          <WhatAreFinancial />
        </div>
        <div className={[classes.col2, ""].join(" ")} ref={col2}>
          <WhatAreForex />
        </div>
        <div className={[classes.col3, ""].join(" ")} ref={col3}>
          <HowDoes />
        </div>
        <div className={[classes.col4, ""].join(" ")} ref={col4}>
          {" "}
          <WhatIsTraded />
        </div>
        <div className={[classes.col5, ""].join(" ")} ref={col5}>
          <HowDoTraders />
        </div>
        <div className={[classes.col6, ""].join(" ")} ref={col6}>
          {" "}
          <HowToBuy />
        </div>
        <div className={[classes.col7, ""].join(" ")} ref={col7}>
          <HowToOpen />
        </div>
        <div className={[classes.col8, ""].join(" ")} ref={col8}>
          {" "}
          <Faq />
        </div>
        <div className={[classes.col9, ""].join(" ")} ref={col9}>
          {" "}
          <Newsletters />
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default LearnPage;
