import React, { useEffect, useRef } from "react";
import classes from "./AboutPage.module.scss";
import Header from "../../components/GlobalComponents/Header/Header";
import Banner from "../../components/AboutUsComponents/Banner/Banner";
import WinnerSlider from "../../components/AboutUsComponents/WinnerSlider/WinnerSlider";
import SecurityClients from "../../components/AboutUsComponents/SecurityClients/SecurityClients";
import BusinessProfiles from "../../components/AboutUsComponents/BusinessProfiles/BusinessProfiles";
import TopPerks from "../../components/AboutUsComponents/TopPerks/TopPerks";
import Newsletters from "../../components/StartPage/Newsletters/Newsletters";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutPage = () => {
  const col1 = useRef(null);
  const col2 = useRef(null);
  const col3 = useRef(null);
  const col4 = useRef(null);
  const col5 = useRef(null);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(col1.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col1.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col2.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col2.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col3.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col3.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col4.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col4.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(col5.current, {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: col5.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="ovf-hidder">
      <Header />
      <Banner />
      <div className={[classes.col1, ""].join(" ")} ref={col1}>
        <WinnerSlider />
      </div>
      <div className={[classes.col1, ""].join(" ")} ref={col2}>
        <SecurityClients />
      </div>
      <div className={classes.aboutPageBg}>
        <div className={[classes.col1, ""].join(" ")} ref={col3}>
          {" "}
          <BusinessProfiles />
        </div>
        <div className={[classes.col1, ""].join(" ")} ref={col4}>
          {" "}
          <TopPerks />
        </div>
      </div>
      <div className={[classes.col1, ""].join(" ")} ref={col5}>
        {" "}
        <Newsletters />
      </div>

      <Footer />
    </div>
  );
};

export default AboutPage;
