import React, { useState } from "react";
import classes from "./WhatIsTraded.module.scss";
import coin_01 from "../../../assets/img/LearnPage/WhatIsTraded/coin_01.png";
import coin_02 from "../../../assets/img/LearnPage/WhatIsTraded/coin_02.png";
import coin_03 from "../../../assets/img/LearnPage/WhatIsTraded/coin_03.png";
import coin_04 from "../../../assets/img/LearnPage/WhatIsTraded/coin_04.png";
import coin_05 from "../../../assets/img/LearnPage/WhatIsTraded/coin_05.png";
import coin_06 from "../../../assets/img/LearnPage/WhatIsTraded/coin_06.png";
import { Trans } from "react-i18next";
import arrow from "../../../assets/img/LearnPage/show_arrow.png";
import { useTranslation } from "react-i18next";

const WhatIsTraded = () => {
  const { t } = useTranslation();
  const cards = [
    {
      image: coin_01,
      text: "learn_53",
    },
    {
      image: coin_02,
      text: "learn_54",
    },
    {
      image: coin_03,
      text: "learn_55",
    },
    {
      image: coin_04,
      text: "learn_56",
    },
    {
      image: coin_05,
      text: "learn_57",
    },
    {
      image: coin_06,
      text: "learn_58",
    },
  ];

  const [hide, setHide] = useState(true);
  return (
    <section className={classes.whatIsTraded}>
      <div className="container">
        <h2 className={[classes.title, "font-45"].join(" ")}>
          {t("learn_51")}
        </h2>
        <p className={[classes.text, "font-20"].join(" ")}>{t("learn_52")}</p>
      </div>
      <div className={[classes.container, "container"].join(" ")}>
        <div className={classes.cards}>
          {cards.map((card, index) => (
            <div className={classes.card} key={index}>
              <img className="no-select" src={card.image} alt={card.text} />
              <p className={classes.desc}>
                <Trans>{t(card.text)}</Trans>
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        <div className={[classes.content, "font-20"].join(" ")}>
          <div className={classes.col}>
            <p>{t("learn_5_left1")}</p>
            <p>{t("learn_5_left2")}</p>
            <div className={`${hide && classes.hide}`}>
              <p>
                <b>{t("learn_5_left3")}</b>
              </p>
            </div>
            <div className={`${hide && classes.hide}`}>
              <p>{t("learn_5_left4")}</p>
            </div>
            <div className={`${hide && classes.hide}`}>
              <p>{t("learn_5_left5")}</p>
            </div>
            <div className={`${hide && classes.hide}`}>
              <p>{t("learn_5_left6")}</p>
            </div>
          </div>
          <div className={`${hide && classes.hide}`}>
            <div className={classes.col}>
              <p>{t("learn_5_right1")}</p>
              <p>{t("learn_5_right2")}</p>
              <p>{t("learn_5_right3")}</p>
            </div>
          </div>
        </div>
        <div
          className={`${classes.showBtn} ${
            hide ? classes.showMore : classes.showLess
          }`}
          onClick={() => setHide((value) => !value)}
        >
          <img src={arrow} alt="Show text" />
          {hide ? "Read more" : "Hide text"}
        </div>
      </div>
    </section>
  );
};

export default WhatIsTraded;
