import React from "react";
import classes from "./HowDoTraders.module.scss";
import image from "../../../assets/img/LearnPage/how_do_traders.png";
import Button from "../../GlobalComponents/Button/Button";

import { useTranslation } from "react-i18next";

const HowDoTraders = () => {
  const { t } = useTranslation();
  return (
    <section className="container">
      <div className={classes.howDoTraders}>
        <h2 className={[classes.title, "font-45"].join(" ")}>
          {t("learn_6_1")}
        </h2>
        <div className={classes.content}>
          <div className={[classes.text, "font-20"].join(" ")}>
            <p> {t("learn_6_2")}</p>
            <p>{t("learn_6_3")}</p>
          </div>
          <div className={classes.btn}>
            <Button> {t("open_btn")}</Button>
          </div>
        </div>
        <img
          className="no-select"
          src={image}
          alt="How Do Traders Earn Money?"
        />
      </div>
    </section>
  );
};

export default HowDoTraders;
