import React, { useRef, useEffect } from "react";
import { useState } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Newsletters.module.scss";
import image from "../../../assets/img/StartPage/newsletters.png";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Newsletters = () => {
  const mainRef = useRef(null);
  const ball1 = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.timeline().to(ball1.current, {
        scale: 1,

        scrollTrigger: {
          trigger: mainRef.current,
          start: "top 900px",
          end: "center 900px",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, []);
  const [email, setEmail] = useState("");
  return (
    <section className="container" ref={mainRef}>
      <div className={classes.newsletters}>
        <div className={classes.content}>
          <h2 className={[classes.title, "font-45"].join(" ")}>
            {t("main_9_1")}
          </h2>
          <div className={[classes.text, "font-20"].join(" ")}>
            <h3 className={classes.subtitle}>{t("main_9_2")}</h3>
            <p>{t("main_9_3")}</p>
            <p>{t("main_9_4")}</p>
            <p>{t("main_9_5")}</p>
          </div>
          <div className={classes.form}>
            <input
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder={t("main_9_6")}
              type="text"
            />
            <div className={classes.btn}>
              <Button>{t("subscribe_btn")}</Button>
            </div>
          </div>
        </div>
        <img className="no-select" src={image} alt="" ref={ball1} />
      </div>
    </section>
  );
};

export default Newsletters;
