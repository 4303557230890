import React from "react";
import classes from "./WhyChoose.module.scss";
import image from "../../../assets/img/StartPage/why_choose.png";
import Button from "../../GlobalComponents/Button/Button";

import { useTranslation } from "react-i18next";

const WhyChoose = () => {
  const { t } = useTranslation();

  return (
    <section className="container">
      <div className={classes.whyChoose}>
        <div className={classes.content}>
          <h2 className={[classes.title, "font-45"].join(" ")}>
            {t("start_4_1")}
          </h2>
          <h3 className={[classes.subtitle, "font-20"].join(" ")}>
            {t("start_4_2")}
          </h3>
          <ul className="font-20">
            <li>{t("start_4_3")}</li>
            <li>{t("start_4_4")}</li>
            <li>{t("start_4_5")}</li>
          </ul>
          <div className={classes.btn}>
            <Button>{t("create_btn")}</Button>
          </div>
        </div>
        <img className="no-select" src={image} alt={t("start_4_1")} />
      </div>
    </section>
  );
};

export default WhyChoose;
