import React from "react";
import classes from "./HowToOpen.module.scss";
import image from "../../../assets/img/LearnPage/how_to_open.png";
import Button from "../../GlobalComponents/Button/Button";

import { useTranslation } from "react-i18next";

const HowToOpen = () => {
  const { t } = useTranslation();
  return (
    <section className="container">
      <div className={classes.howDoTraders}>
        <h2 className={[classes.title, "font-45"].join(" ")}>
          {t("learn_8_1")}
        </h2>
        <div className={classes.content}>
          <ul className={[classes.text, "font-20"].join(" ")}>
            <li>{t("learn_8_2")}</li>
            <li>{t("learn_8_3")}</li>
            <li>{t("learn_8_4")}</li>
          </ul>
          <div className={classes.btn}>
            <Button> {t("open_btn")}</Button>
          </div>
        </div>
        <img className="no-select" src={image} alt={t("learn_8_1")} />
      </div>
    </section>
  );
};

export default HowToOpen;
