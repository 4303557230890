import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./TradingTable.module.scss";

import { useTranslation } from "react-i18next";

const TradingTable = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.table}>
      <div className={classes.tableBody}>
        <div className={["container", classes.cont].join(" ")}>
          <div className={[classes.tableTitle, "font-45"].join(" ")}>
            {t("trading_2_title")}
          </div>
          <div className={classes.tableWrapper}>
            <div className={classes.tableContent}>
              <div className={classes.tableTop}>
                <div className={classes.tableTopColumn}>
                  {t("trading_2_row1_1")}
                </div>
                <div className={classes.tableTopColumn}>
                  {t("trading_2_row1_2")}
                </div>
                <div className={classes.tableTopColumn}>
                  {t("trading_2_row1_3")}
                </div>
                <div className={classes.tableTopColumn}>
                  {t("trading_2_row1_4")}
                </div>
                <div className={classes.tableTopColumn}>
                  {t("trading_2_row1_5")}
                </div>
              </div>
              <div className={classes.tableLine}></div>
              <div className={classes.tableRow}>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row2_1")}
                </div>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row2_2")}
                </div>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row2_3")}
                </div>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row2_4")}
                </div>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row2_5")}
                </div>
              </div>
              <div className={classes.tableLine}></div>
              <div className={classes.tableRow}>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row3_1")}
                </div>
                <div className={classes.tableRowColumn}>
                  <ul className={classes.tableRowColumnList}>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_21")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_22")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_23")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_24")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_25")}
                    </li>
                  </ul>
                </div>
                <div className={classes.tableRowColumn}>
                  <ul className={classes.tableRowColumnList}>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_31")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_32")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_33")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_34")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_35")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_36")}
                    </li>
                  </ul>
                </div>
                <div className={classes.tableRowColumn}>
                  <ul className={classes.tableRowColumnList}>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_41")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_42")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_43")}
                    </li>
                  </ul>
                </div>
                <div className={classes.tableRowColumn}>
                  <ul className={classes.tableRowColumnList}>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_51")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_52")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_53")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_54")}
                    </li>
                    <li className={classes.tableRowColumnListItem}>
                      {t("trading_2_row3_55")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className={classes.tableLine}></div>
              <div className={classes.tableRow}>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row4_1")}
                </div>
                <div className={classes.tableRowColumn}>10 USD / 10 EUR</div>
                <div className={classes.tableRowColumn}>10 USD / 10 EUR</div>
                <div className={classes.tableRowColumn}>10 USD / 10 EUR</div>
                <div className={classes.tableRowColumn}>10 USD / 10 EUR</div>
              </div>
              <div className={classes.tableLine}></div>
              <div className={classes.tableRow}>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row5_1")}
                </div>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row5_2")}
                </div>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row5_3")}
                </div>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row5_4")}
                </div>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row5_5")}
                </div>
              </div>
              <div className={classes.tableLine}></div>
              <div className={classes.tableRow}>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row6_1")}
                </div>
                <div className={classes.tableRowColumn}>1:300</div>
                <div className={classes.tableRowColumn}>1:500</div>
                <div className={classes.tableRowColumn}>1:2000</div>
                <div className={classes.tableRowColumn}>1:2000</div>
              </div>
              <div className={classes.tableLine}></div>
              <div className={classes.tableRow}>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row7_1")}
                </div>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row7_2")}
                </div>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row7_3")}
                </div>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row7_4")}
                </div>
                <div className={classes.tableRowColumn}>
                  {t("trading_2_row7_5")}
                </div>
              </div>
              <div className={classes.tableLine}></div>
              <div
                className={[classes.tableRow, classes.tableRowLast].join(" ")}
              >
                <div className={classes.tableRowColumn}></div>
                <div className={classes.tableRowColumn}>
                  <Button>{t("open_btn")}</Button>
                </div>
                <div className={classes.tableRowColumn}>
                  <Button>{t("open_btn")}</Button>
                </div>
                <div className={classes.tableRowColumn}>
                  <Button>{t("open_btn")}</Button>
                </div>
                <div className={classes.tableRowColumn}>
                  <Button>{t("open_btn")}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingTable;
