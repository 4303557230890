import React, { useRef, useEffect } from "react";
import classes from "./Banner.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import image from "../../../assets/img/StartPage/Banner/image.png";

import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Banner = () => {
  const firstRef = useRef(null);

  const { t } = useTranslation();
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.to(firstRef.current, {
        x: 0,
        opacity: 1,
        scale: 1,
        delay: 0.5,
        duration: 1.5,
      });
    }
  }, []);
  return (
    <section className={classes.banner}>
      <div className={["container", classes.container].join(" ")}>
        <div className={classes.content}>
          <h1 className={[classes.title, "font-45-banner"].join(" ")}>
            {t("start_1_1")}
          </h1>
          <div className={[classes.text, "font-25"].join(" ")}>
            <p> {t("start_1_2")}</p>
            <p> {t("start_1_3")}</p>
            <p> {t("start_1_4")}</p>
          </div>
          <div className={classes.btn}>
            <Button> {t("open_btn")}</Button>
          </div>
        </div>
        <img
          className={[classes.image, "no-select"].join(" ")}
          ref={firstRef}
          src={image}
          alt=""
        />
      </div>
    </section>
  );
};

export default Banner;
