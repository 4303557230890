import React from "react";
import classes from "./ClientMoney.module.scss";

import { useTranslation } from "react-i18next";

const ClientMoney = () => {
  const { t } = useTranslation();
  return (
    <section className="container">
      <div className={classes.clientMoney}>
        <div className={classes.col}>
          <h2 className={[classes.title, "font-45"].join(" ")}>
            {t("accounts_4_1")}
          </h2>
          <p className={[classes.text, "font-20"].join(" ")}>
            {t("accounts_4_2")}
          </p>
        </div>
        <div className={classes.col}>
          <h2 className={[classes.title, "font-45"].join(" ")}>
            {t("accounts_4_3")}
          </h2>
          <p className={[classes.text, "font-20"].join(" ")}>
            {t("accounts_4_4")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default ClientMoney;
