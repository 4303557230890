import React from "react";
import classes from "./EasyTrading.module.scss";
import { useTranslation } from "react-i18next";

const EasyTrading = () => {
  const { t } = useTranslation();
  return (
    <section className="container">
      <div className={classes.easyTrading}>
        <h2 className={[classes.title, "font-45"].join(" ")}>
          {t("start_2_1")}
        </h2>
        <div className={[classes.content, "font-20"].join(" ")}>
          <ul>
            <li>{t("start_2_2")}</li>
            <li>{t("start_2_3")}</li>
          </ul>
          <ul>
            <li>{t("start_2_4")}</li>
            <li>{t("start_2_5")}</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default EasyTrading;
