import React, { useState } from "react";
import classes from "./Tabs.module.scss";
import { Trans } from "react-i18next";

import { useTranslation } from "react-i18next";

const Tabs = () => {
  const { t } = useTranslation();
  const btns = [
    {
      id: 1,
      text: t("accounts_4_top1"),
    },
    {
      id: 2,
      text: t("accounts_4_top2"),
    },
    {
      id: 3,
      text: t("accounts_4_top3"),
    },
    {
      id: 4,
      text: t("accounts_4_top4"),
    },
  ];

  const table = {
    1: [
      {
        col_01: "AUDCAD",
        col_02: "AUD 100 000",
        col_03: "0.00033",
        col_04: "0.00035",
        col_05: "1:500 (0.2%)",
        col_06: "-0.005345% /<br/>0.002151%",
        col_07: "-",
        col_08: "24 h from Sunday 11:00 pm<br/>to Friday 10:00 pm CET",
        col_09: t("accounts_4_table1_interbank"),
      },
      {
        col_01: "AUDCHF",
        col_02: "AUD 100 000",
        col_03: "0.0003",
        col_04: "0.0003",
        col_05: "1:500 (0.2%)",
        col_06: "0.003203% /<br/>-0.007783%",
        col_07: "-",
        col_08: "24 h from Sunday 11:00 pm<br/>to Friday 10:00 pm CET",
        col_09: t("accounts_4_table1_interbank"),
      },
      {
        col_01: "AUDJPY",
        col_02: "AUD 100 000",
        col_03: "0.024",
        col_04: "0.027",
        col_05: "1:500 (0.2%)",
        col_06: "0.005925% /<br/>-0.010504%",
        col_07: "-",
        col_08: "24 h from Sunday 11:00 pm<br/>to Friday 10:00 pm CET",
        col_09: t("accounts_4_table1_interbank"),
      },
      {
        col_01: "AUDNZD",
        col_02: "AUD 100 000",
        col_03: "0.00021",
        col_04: "0.00023",
        col_05: "1:500 (0.2%)",
        col_06: "-0.005136% /<br/>0.001249%",
        col_07: "-",
        col_08: "24 h from Sunday 11:00 pm<br/>to Friday 10:00 pm CET",
        col_09: t("accounts_4_table1_interbank"),
      },
    ],
    2: [
      {
        col_01: "AUS200",
        col_02: t("accounts_4_table3_instrument") + "  USD 25",
        col_03: "4",
        col_04: "5",
        col_05: "1:100 (1%)",
        col_06: "-0.024713% /<br/>-0.000287%",
        col_07: "-",
        col_08:
          "12:05 am - 6:30 am and<br/>7:15 am - 09:00 pm CET;<br/>2:05 am - 8:30 am and<br/>9:15 am - 23:00 pm CEST",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "AUT20",
        col_02: t("accounts_4_table3_instrument") + " EUR 10",
        col_03: "18",
        col_04: "25",
        col_05: "1:100 (1%)",
        col_06: "-0.017778% /<br/>-0.007222%",
        col_07: "-",
        col_08: "9:10 am - 5:00 pm",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "BRAComp",
        col_02: t("accounts_4_table3_instrument") + "  USD 2",
        col_03: "82",
        col_04: "125",
        col_05: "1:100 (1%)",
        col_06: "-0.024713% /<br/>-0.000287%",
        col_07: "-",
        col_08: "1:05 pm - 9:55 pm CET;<br/>2:05 pm - 10:55 pm CEST",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "CH50cash",
        col_02: t("accounts_4_table3_instrument") + "  USD 10",
        col_03: "-",
        col_04: "-",
        col_05: "1:33 (3%)",
        col_06: "-0.002053% /<br/>0.000000%",
        col_07: "-",
        col_08: "12:00 am - 11:00 pm",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "CHNComp",
        col_02: t("accounts_4_table3_instrument") + "  USD 10",
        col_03: "12",
        col_04: "20",
        col_05: "1:100 (1%)",
        col_06: "-0.024713% /<br/>-0.000287%",
        col_07: "-",
        col_08:
          "2:20 am - 5:00 am and<br/>6:35 am - 9:15 am and<br/>10:20 am - 8 pm CET;<br/>3:20 am - 6:00 am and<br/>7:35 am - 10:15 am and<br/>11:20 am - 9 pm CEST",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "CZKCASH",
        col_02: t("accounts_4_table3_instrument") + "  USD 100",
        col_03: "2.8",
        col_04: "3",
        col_05: "1:100 (1%)",
        col_06: "-0.024713% /<br/>-0.000287%",
        col_07: "-",
        col_08: "9:20 am - 4:00 pm",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "DE30",
        col_02: t("accounts_4_table3_instrument") + "  EUR 25",
        col_03: "0.7",
        col_04: "1",
        col_05: "1:200 (0.5%)",
        col_06: "-0.016389% /<br/>-0.005833%",
        col_07: "-",
        col_08: "1:15 am - 10:00 pm CET,<br/>2:15 am - 10:00 pm CEST",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "DE30.cash",
        col_02: t("accounts_4_table3_instrument") + "  EUR 25",
        col_03: "0.9",
        col_04: "1",
        col_05: "1:200 (0.5%)",
        col_06: "-",
        col_07: "-",
        col_08: "1:30 am - 10:00 pm CET;<br/>2:30 am– 10:00 pm CEST",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "EU50",
        col_02: t("accounts_4_table3_instrument") + "  EUR 10",
        col_03: "1.4",
        col_04: "2",
        col_05: "1:200 (0.5%)",
        col_06: "-0.017778% /<br/>-0.007222%",
        col_07: "-",
        col_08: "1:15 am - 10:00 pm CET,<br/>2:15 am – 10:00 pm CEST",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "FRA40",
        col_02: t("accounts_4_table3_instrument") + "  EUR 10",
        col_03: "0.8",
        col_04: "1.1",
        col_05: "1:133 (0.75%)",
        col_06: "-0.017778% /<br/>-0.007222%",
        col_07: "-",
        col_08: "8:00 am - 10:00 pm",
        col_09: t("accounts_4_table2_market"),
      },
    ],
    3: [
      {
        col_01: "ALUMINIUM",
        col_02: t("accounts_4_table3_instrument") + "  USD 50",
        col_03: "8",
        col_04: "12",
        col_05: "1:67 (1.5%)",
        col_06: "-0.024713% /<br/>-0.000287%",
        col_07: "-",
        col_08: "8:30 am - 7:30 pm",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "BUND10Y",
        col_02: t("accounts_4_table3_instrument") + "  USD 1000",
        col_03: "0.02",
        col_04: "0.02",
        col_05: "1:200 (0.5%)",
        col_06: "-0.024713% /<br/>-0.000287%",
        col_07: "-",
        col_08: "2:15 am - 10:00 pm",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "CATTLE",
        col_02: t("accounts_4_table3_instrument") + "  USD 400",
        col_03: "0.070",
        col_04: "0.100",
        col_05: "1:67 (1.5%)",
        col_06: "-0.024713% /<br/>-0.000287%",
        col_07: "-",
        col_08: "03:30 Pm - 08:05 pm",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "COCOA",
        col_02: t("accounts_4_table3_instrument") + "  USD 10",
        col_03: "11",
        col_04: "13",
        col_05: "1:67 (1.5%)",
        col_06: "-0.024713% /<br/>-0.000287%",
        col_07: "-",
        col_08: "10:45 am - 7:30 pm",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "COFFEE",
        col_02: t("accounts_4_table3_instrument") + "  USD 2000",
        col_03: "0.25",
        col_04: "0.3",
        col_05: "1:67 (1.5%)",
        col_06: "-0.024713% /<br/>-0.000287%",
        col_07: "-",
        col_08: "10:45 am - 7:30 pm",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "COPPER",
        col_02: t("accounts_4_table3_instrument") + "  USD 30",
        col_03: "18",
        col_04: "23",
        col_05: "1:67 (1.5%)",
        col_06: "-0.024713% /<br/>-0.000287%",
        col_07: "-",
        col_08: "8:30 am - 7:30 pm",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "CORN",
        col_02: t("accounts_4_table3_instrument") + "  USD 500",
        col_03: "0.36",
        col_04: "0.39",
        col_05: "1:67 (1.5%)",
        col_06: "-0.024713% /<br/>-0.000287%",
        col_07: "-",
        col_08: "2:05 am - 2:45 pm and<br/>3:35 pm - 8:00 pm",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "COTTON",
        col_02: t("accounts_4_table3_instrument") + "  USD 500",
        col_03: "0.19",
        col_04: "0.19",
        col_05: "1:67 (1.5%)",
        col_06: "713% /<br/>-0.000287%",
        col_07: "-",
        col_08: "3:05 am - 8:20 pm",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "EMISS",
        col_02: t("accounts_4_table3_instrument") + "  USD 5000",
        col_03: "0.05",
        col_04: "0.1",
        col_05: "1:67 (1.5%)",
        col_06: "-0.024713% /<br/>-0.000287%",
        col_07: "-",
        col_08: "8:05 am - 6:00 pm",
        col_09: t("accounts_4_table2_market"),
      },
      {
        col_01: "EMISS",
        col_02: t("accounts_4_table3_instrument") + "  USD 420",
        col_03: "0.30",
        col_04: "0.50",
        col_05: "1:67 (1.5%)",
        col_06: "-0.024713% /<br/>-0.000287%",
        col_07: "-",
        col_08: "12:00 am - 11:00 pm",
        col_09: t("accounts_4_table2_market"),
      },
    ],
    4: [
      {
        col_01: "AAVE",
        col_02: "1 AAVE",
        col_03: "-",
        col_04: "-",
        col_05: "1:2 (50%)",
        col_06: "-0.097222% /<br/>-0.027778%",
        col_07: "-",
        col_08: "24 h from Saturday<br/>4:00 am to Friday 10:00 pm",
        col_09: t("accounts_4_table3_market"),
      },
      {
        col_01: "ALGORAND",
        col_02: "1 ALGORAND",
        col_03: "-",
        col_04: "-",
        col_05: "1:2 (50%)",
        col_06: "-0.097222% /<br/>-0.027778%",
        col_07: "-",
        col_08: "24 h from Saturday<br/>4:00 am to Friday 10:00 pm",
        col_09: t("accounts_4_table3_market"),
      },
      {
        col_01: "APECOIN",
        col_02: "1 APECOIN",
        col_03: "-",
        col_04: "-",
        col_05: "1:2 (50%)",
        col_06: "-0.097222% /<br/>-0.027778%",
        col_07: "-",
        col_08: "24 h from Saturday<br/>4:00 am to Friday 10:00 pm",
        col_09: t("accounts_4_table3_market"),
      },
      {
        col_01: "AVALANCHE",
        col_02: "1 AVALANCHE",
        col_03: "-",
        col_04: "-",
        col_05: "-",
        col_06: "-0.097222% /<br/>-0.027778%",
        col_07: "-",
        col_08: "24 h from Saturday<br/>4:00 am to Friday 10:00 pm",
        col_09: t("accounts_4_table3_market"),
      },
      {
        col_01: "BINANCECOIN",
        col_02: "1 BINANCECOIN",
        col_03: "-",
        col_04: "-",
        col_05: "1:4 (25%)",
        col_06: "-0.097222% /<br/>-0.027778%",
        col_07: "-",
        col_08: "24 h from Saturday<br/>4:00 am to Friday 10:00 pm",
        col_09: t("accounts_4_table3_market"),
      },
      {
        col_01: "BITCOIN",
        col_02: "1 BITCOIN",
        col_03: "0.50%",
        col_04: "0.5% - 1%",
        col_05: "1:5 (20%)",
        col_06: "-0.097222% /<br/>-0.027778%",
        col_07: "-",
        col_08: "24 h from Saturday<br/>4:00 am to Friday 10:00 pm",
        col_09: t("accounts_4_table3_market"),
      },
      {
        col_01: "BITCOINCASH",
        col_02: "1 BITCOINCASH",
        col_03: "2.00%",
        col_04: "2% - 2.5%",
        col_05: "1:5 (20%)",
        col_06: "-0.097222% /<br/>-0.027778%",
        col_07: "-",
        col_08: "24 h from Saturday<br/>4:00 am to Friday 10:00 pm",
        col_09: t("accounts_4_table3_market"),
      },
      {
        col_01: "CARDANO",
        col_02: "1 CARDANO",
        col_03: "-",
        col_04: "-",
        col_05: "1:4 (25%)",
        col_06: "-0.097222% /<br/>-0.027778%",
        col_07: "-",
        col_08: "24 h from Saturday<br/>4:00 am to Friday 10:00 pm",
        col_09: t("accounts_4_table3_market"),
      },
      {
        col_01: "CHAINLINK",
        col_02: "1 CHAINLINK",
        col_03: "-",
        col_04: "-",
        col_05: "1:4 (25%)",
        col_06: "-0.097222% /<br/>-0.027778%",
        col_07: "-",
        col_08: "24 h from Saturday<br/>4:00 am to Friday 10:00 pm",
        col_09: t("accounts_4_table3_market"),
      },
      {
        col_01: "CHILIZ",
        col_02: "1 CHILIZ",
        col_03: "-",
        col_04: "-",
        col_05: "1:2 (50%)",
        col_06: "-0.097222% /<br/>-0.027778%",
        col_07: "-",
        col_08: "24 h from Saturday<br/>4:00 am to Friday 10:00 pm",
        col_09: t("accounts_4_table3_market"),
      },
    ],
  };
  const [currentTable, setCurrentTable] = useState(1);
  return (
    <div className={classes.tabs}>
      <div className="container">
        <div className={classes.btns}>
          {btns.map((btn) => (
            <div
              className={`${classes.btn} ${
                btn.id === currentTable && classes.btnActive
              }`}
              onClick={() => setCurrentTable(btn.id)}
              key={btn.id}
            >
              {btn.text}
            </div>
          ))}
        </div>
      </div>
      <div className={["container", classes.tableContainer].join(" ")}>
        <table>
          <tbody>
            <tr className={classes.head}>
              <td>
                <Trans>{t("accounts_4_tabletop1")}</Trans>
              </td>
              <td>
                <Trans>{t("accounts_4_tabletop2")}</Trans>
              </td>
              <td>
                <Trans>{t("accounts_4_tabletop3")}</Trans>
              </td>
              <td>
                <Trans>{t("accounts_4_tabletop4")}</Trans>
              </td>
              <td>
                <Trans>{t("accounts_4_tabletop5")}</Trans>
              </td>
              <td>
                <Trans>{t("accounts_4_tabletop6")}</Trans>
              </td>
              <td>
                <Trans>{t("accounts_4_tabletop7")}</Trans>
              </td>
              <td>
                <Trans>{t("accounts_4_tabletop8")}</Trans>
              </td>
              <td>
                <Trans>{t("accounts_4_tabletop9")}</Trans>
              </td>
            </tr>
            {table[currentTable].map((row, rowIndex) => {
              const keys = Object.keys(row);
              return (
                <tr key={rowIndex}>
                  {keys.map((rowKey, index) => (
                    <td key={index}>
                      <Trans>
                        {table[currentTable][rowIndex][`${rowKey}`]}
                      </Trans>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Tabs;
