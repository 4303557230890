import React from "react";
import { useState } from "react";
import classes from "./WhatAreFinancial.module.scss";
import arrow from "../../../assets/img/LearnPage/show_arrow.png";

import { Trans, useTranslation } from "react-i18next";

const WhatAreFinancial = () => {
  const { t } = useTranslation();
  const [hide, setHide] = useState(true);
  return (
    <section className="container">
      <div className={classes.whatAreFinancial}>
        <h2 className={[classes.title, "font-45"].join(" ")}>
          {t("learn_21")}
        </h2>
        <div className={[classes.content, "font-20"].join(" ")}>
          <div className={classes.col}>
            <p>{t("learn_2_left1")}</p>
            <p>
              <b>{t("learn_2_left2")}</b>
            </p>
            <div className={`${hide && classes.hide}`}>
              <ul>
                <li>{t("learn_2_left3")}</li>
                <li>{t("learn_2_left4")}</li>
                <li>{t("learn_2_left5")}</li>
                <li>{t("learn_2_left6")}</li>
                <li>{t("learn_2_left7")}</li>
              </ul>
            </div>
          </div>
          <div className={`${hide && classes.hide}`}>
            <div className={classes.col}>
              <p>
                <b>{t("learn_2_right1")}</b>
              </p>
              <p>
                <Trans>{t("learn_2_right2")}</Trans>
              </p>
              <p>
                <Trans>{t("learn_2_right3")}</Trans>
              </p>
              <p>
                <Trans>{t("learn_2_right4")}</Trans>
              </p>
              <p>
                <Trans>{t("learn_2_right5")}</Trans>
              </p>
            </div>
          </div>
        </div>
        <div
          className={`${classes.showBtn} ${
            hide ? classes.showMore : classes.showLess
          }`}
          onClick={() => setHide((value) => !value)}
        >
          <img src={arrow} alt="Show text" />
          {hide ? "Read more" : "Hide text"}
        </div>
      </div>
    </section>
  );
};

export default WhatAreFinancial;
