import React, { useRef, useState } from "react";
import classes from "./Switch.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Switch.scss";
import $ from "jquery";
import "swiper/css";
import img1 from "../../../assets/img/MainPage/3/left.png";
import "swiper/swiper.min.css";
import { Navigation, Pagination } from "swiper";
import { useTranslation } from "react-i18next";

const Switch = () => {
  const { t } = useTranslation();
  const [my_swiper, set_my_swiper] = useState({});
  const myRef = useRef(null);
  function onMouseEnterSlide(i) {
    if (window.innerWidth > 992) {
      $($($(i.target).children()[0]).children()[1]).slideDown(300);
    }
  }
  function onMouseLeaveSlide(i) {
    if (window.innerWidth > 992) {
      $($($(i.target).children()[0]).children()[1]).slideUp(300);
    }
  }
  return (
    <div className={classes.eight}>
      <div className="container">
        <div className={[classes.eightTitle, "font-45"].join(" ")}>
          {t("main_3_1")}
        </div>
        <div className={[classes.eightSubtitle, "font-20"].join(" ")}>
          {t("main_3_2")}
        </div>
        <div className={classes.eightSlider} id="myFirstSlider">
          <Swiper
            modules={[Navigation, Pagination]}
            pagination={{ clickable: true, el: myRef.current }}
            spaceBetween={120}
            slidesPerView={3}
            loop={true}
            breakpoints={{
              1351: {
                slidesPerView: 3,
              },
              1000: {
                spaceBetween: 30,
              },
              768: {
                spaceBetween: 20,
                slidesPerView: 2,
              },
              100: {
                slidesPerView: 1,
              },
            }}
            onInit={(ev) => {
              set_my_swiper(ev);
            }}
          >
            <SwiperSlide>
              <div
                className={[
                  classes.eightSliderSlide,
                  classes.eightSliderSlide1,
                ].join(" ")}
                onMouseEnter={onMouseEnterSlide}
                onMouseLeave={onMouseLeaveSlide}
              >
                <div className={classes.eightSliderSlideBottom}>
                  <div
                    className={[
                      classes.eightSliderSlideBottomTitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_3_item11")}
                  </div>
                  <div
                    className={[
                      classes.eightSliderSlideBottomSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_3_item12")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className={[
                  classes.eightSliderSlide,
                  classes.eightSliderSlide2,
                ].join(" ")}
                onMouseEnter={onMouseEnterSlide}
                onMouseLeave={onMouseLeaveSlide}
              >
                <div className={classes.eightSliderSlideBottom}>
                  <div
                    className={[
                      classes.eightSliderSlideBottomTitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_3_item21")}
                  </div>
                  <div
                    className={[
                      classes.eightSliderSlideBottomSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_3_item22")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className={[
                  classes.eightSliderSlide,
                  classes.eightSliderSlide3,
                ].join(" ")}
                onMouseEnter={onMouseEnterSlide}
                onMouseLeave={onMouseLeaveSlide}
              >
                <div className={classes.eightSliderSlideBottom}>
                  <div
                    className={[
                      classes.eightSliderSlideBottomTitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_3_item31")}
                  </div>
                  <div
                    className={[
                      classes.eightSliderSlideBottomSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_3_item32")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className={[
                  classes.eightSliderSlide,
                  classes.eightSliderSlide4,
                ].join(" ")}
                onMouseEnter={onMouseEnterSlide}
                onMouseLeave={onMouseLeaveSlide}
              >
                <div className={classes.eightSliderSlideBottom}>
                  <div
                    className={[
                      classes.eightSliderSlideBottomTitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_3_item41")}
                  </div>
                  <div
                    className={[
                      classes.eightSliderSlideBottomSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_3_item42")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className={[
                  classes.eightSliderSlide,
                  classes.eightSliderSlide5,
                ].join(" ")}
                onMouseEnter={onMouseEnterSlide}
                onMouseLeave={onMouseLeaveSlide}
              >
                <div className={classes.eightSliderSlideBottom}>
                  <div
                    className={[
                      classes.eightSliderSlideBottomTitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_3_item51")}
                  </div>
                  <div
                    className={[
                      classes.eightSliderSlideBottomSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_3_item52")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className={[
                  classes.eightSliderSlide,
                  classes.eightSliderSlide6,
                ].join(" ")}
                onMouseEnter={onMouseEnterSlide}
                onMouseLeave={onMouseLeaveSlide}
              >
                <div className={classes.eightSliderSlideBottom}>
                  <div
                    className={[
                      classes.eightSliderSlideBottomTitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_3_item61")}
                  </div>
                  <div
                    className={[
                      classes.eightSliderSlideBottomSubtitle,
                      "font-20",
                    ].join(" ")}
                  >
                    {t("main_3_item62")}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className={classes.eightArrows}>
            <div className={classes.eightArrowsRow}>
              <div
                className={classes.eightArrowsRowLeft}
                onClick={() => {
                  my_swiper.slidePrev();
                }}
              >
                <img src={img1} alt="" />
              </div>
              <div
                className={classes.eightArrowsRowRight}
                onClick={() => {
                  my_swiper.slideNext();
                }}
              >
                <img src={img1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="swiper-pagination1" ref={myRef}></div>
    </div>
  );
};

export default Switch;
