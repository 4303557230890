import React from "react";
import classes from "./Input.module.scss";

const Input = (props) => {
  return (
    <div
      className={
        !props.inputValues.inputValid && props.inputValues.isDirty
          ? [classes.input, classes.error].join(" ")
          : classes.input
      }
    >
      <input
        onChange={props.inputValues.onChange}
        onBlur={props.inputValues.onBlur}
        value={props.inputValues.value}
        type={props.eyeHidden === true ? "number" : "text"}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default Input;
