import React, { useEffect } from 'react';
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from '../../components/VattenfallPage/Banner/Banner';
import PowerRevolution from '../../components/VattenfallPage/PowerRevolution/PowerRevolution';
import PowerGeneration from '../../components/VattenfallPage/PowerGeneration/PowerGeneration';
import AboutUs from '../../components/VattenfallPage/AboutUs/AboutUs';
import ActivelyLeading from '../../components/VattenfallPage/ActivelyLeading/ActivelyLeading';
import Activities from '../../components/VattenfallPage/Activities/Activities';

const VattenfallPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <section>
            <Header />
            <Banner />
            <Activities />
            <ActivelyLeading />
            <PowerRevolution />
            <PowerGeneration />
            <AboutUs />
            <Footer />
        </section>
    );
};

export default VattenfallPage;
