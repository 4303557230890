import React, { useRef, useEffect } from "react";
import classes from "./Banner.module.scss";
import image from "../../../assets/img/AboutPage/banner.png";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
  const firstRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.to(firstRef.current, {
        y: 0,
        opacity: 1,
        scale: 1,
        delay: 0.5,
        duration: 1.5,
      });
    }
  }, []);
  return (
    <section className={classes.banner}>
      <div className={["container", classes.containerBanner].join(" ")}>
        <div className={classes.content}>
          <h1 className={[classes.title, "font-45-banner"].join(" ")}>
            {t("about_1_1")}
          </h1>
          <div className={[classes.text, "font-20"].join(" ")}>
            <p> {t("about_1_2")}</p>
            <p>{t("about_1_3")}</p>
          </div>
        </div>
        <div className={classes.imageWrap} ref={firstRef}>
          <img
            className={[classes.image, "no-select"].join(" ")}
            src={image}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default Banner;
