import React from "react";
import classes from "./BusinessProfiles.module.scss";

import { useTranslation } from "react-i18next";

const BusinessProfiles = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.businessProfilesContainer}>
      <h3 className={[classes.businessProfilesTitle, "font-45"].join(" ")}>
        {t("about_4_1")}
      </h3>
      <div className={classes.businessProfilesOverflowXScroll}>
        <div className={classes.businessProfilesTable}>
          <div className={classes.businessProfilesItem}>
            <div className={classes.businessProfilesCol}>
              <p className="font-16"> {t("about_4_left1")}</p>
            </div>
            <div className={classes.businessProfilesCol}>
              <p className="font-16">2015</p>
            </div>
          </div>
          <div className={classes.businessProfilesItem}>
            <div className={classes.businessProfilesCol}>
              <p className="font-16">{t("about_4_left2")}</p>
            </div>
            <div className={classes.businessProfilesCol}>
              <p className="font-16">New Penderel House 4th Floor,<br/> 283-288 High Holborn, London, <br/>United Kingdom, WC1V 7HP</p>
            </div>
          </div>
          <div className={classes.businessProfilesItem}>
            <div className={classes.businessProfilesCol}>
              <p className="font-16">{t("about_4_left3")}</p>
            </div>
            <div className={classes.businessProfilesCol}>
              <p className="font-16">8</p>
            </div>
          </div>
          <div className={classes.businessProfilesItem}>
            <div className={classes.businessProfilesCol}>
              <p className="font-16">{t("about_4_left4")}</p>
            </div>
            <div className={classes.businessProfilesCol}>
              <p className="font-16">{t("about_4_right2")}</p>
            </div>
          </div>
          <div className={classes.businessProfilesItem}>
            <div className={classes.businessProfilesCol}>
              <p className="font-16">{t("about_4_left5")}</p>
            </div>
            <div className={classes.businessProfilesCol}>
              <p className="font-16">{t("about_4_right3")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessProfiles;
