import { useEffect, useState } from "react";
import { validateEmail, isValid } from "../utils/validations/validations";

export const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState({ valid: true, label: "empty" })
    const [minLengthError, setMinLengthError] = useState({ valid: false, label: "minLength" })
    const [maxLengthError, setMaxLengthError] = useState({ valid: false, label: "maxLength" })
    const [emailError, setEmailError] = useState({ valid: false, label: 'email is invalidate' })
    const [phoneError, setPhoneError] = useState({ valid: false, label: 'email is invalidate' })
    const [inputValid, setInputValid] = useState(false)

    useEffect(() => {
        for (const validation in validations) {
            // eslint-disable-next-line
            switch (validation) {
                case 'minLength':
                    value.length < validations[validation] ?
                        setMinLengthError({ ...minLengthError, valid: true }) : setMinLengthError({ ...minLengthError, valid: false })
                    break;
                case 'maxLength':
                    value.length > validations[validation] ? setMaxLengthError({ ...maxLengthError, valid: true }) : setMaxLengthError({ ...maxLengthError, valid: false })
                    break;
                case 'isEmpty':
                    !value ? setEmpty({ ...isEmpty, valid: true }) : setEmpty({ ...isEmpty, valid: false })
                    break;
                case 'isEmail':
                    validateEmail(value) ? setEmailError({ ...emailError, valid: true }) : setEmailError({ ...emailError, valid: false })
                    break;
                case 'phone':
                    !isValid(value) ? setPhoneError({ ...phoneError, valid: true }) : setPhoneError({ ...phoneError, valid: false })
                    break;
            }

        }
        // eslint-disable-next-line
    }, [value])

    useEffect(() => {
        if (isEmpty.valid || maxLengthError.valid || minLengthError.valid || emailError.valid || phoneError.valid) {
            setInputValid(false)
        } else {
            setInputValid(true)
        }
    }, [isEmpty.valid, maxLengthError.valid, minLengthError.valid, emailError.valid, phoneError.valid])

    return {
        valid: [
            isEmpty,
            minLengthError,
            maxLengthError,
            emailError,
            phoneError
        ],
        inputValid
    }
}