import React, { useRef, useState } from "react";
import classes from "./WinnerSlider.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../MainPageComponents/Switch/Switch.scss";
import $ from "jquery";
import "swiper/css";
import { Trans, useTranslation } from "react-i18next";
import img1 from "../../../assets/img/AboutPage/awarding_trophy.png";
import arrow from "../../../assets/img/MainPage/3/left.png";
import "swiper/swiper.min.css";
import { Navigation, Pagination } from "swiper";

const WinnerSlider = () => {
  const { t } = useTranslation();
  const [my_swiper, set_my_swiper] = useState({});
  const myRef = useRef(null);
  function onMouseEnterSlide(i) {
    if (window.innerWidth > 992) {
      $($($(i.target).children()[0]).children()[1]).slideDown(300);
    }
  }
  function onMouseLeaveSlide(i) {
    if (window.innerWidth > 992) {
      $($($(i.target).children()[0]).children()[1]).slideUp(300);
    }
  }
  return (
    <div className={classes.winnerSlider}>
      <h3 className={[classes.sliderTitle, "font-45"].join(" ")}>
        {t("about_2_1")}
      </h3>
      <p className={[classes.sliderText, "font-20 fw-bolder"].join(" ")}>
        {t("about_2_2")}
      </p>
      <Swiper
        modules={[Navigation, Pagination]}
        pagination={{ clickable: true, el: myRef.current }}
        spaceBetween={120}
        slidesPerView={1}
        loop={true}
        onInit={(ev) => {
          set_my_swiper(ev);
        }}
        className="no-select"
      >
        <SwiperSlide>
          <div
            className={[
              classes.winnerSliderSlide,
              classes.winnerSliderSlide6,
            ].join(" ")}
            onMouseEnter={onMouseEnterSlide}
            onMouseLeave={onMouseLeaveSlide}
          >
            <div className={classes.winnerSliderSlideBottom}>
              <div className={classes.winnerSliderImg}>
                <img src={img1} alt="trophey" />
              </div>
              <div className={classes.winnerSliderContentWrap}>
                <div
                  className={[
                    classes.winnerSliderSlideBottomTitle,
                    "font-18 fw-bold text-center",
                  ].join(" ")}
                >
                  <Trans>{t("about_2_slide1_1")}</Trans>
                </div>
                <div
                  className={[
                    classes.winnerSliderSlideBottomSubtitle,
                    "font-16 fw-light text-center",
                  ].join(" ")}
                >
                  <Trans>{t("about_2_slide1_2")}</Trans>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className={[
              classes.winnerSliderSlide,
              classes.winnerSliderSlide6,
            ].join(" ")}
            onMouseEnter={onMouseEnterSlide}
            onMouseLeave={onMouseLeaveSlide}
          >
            <div className={classes.winnerSliderSlideBottom}>
              <div className={classes.winnerSliderImg}>
                <img src={img1} alt="trophey" />
              </div>
              <div className={classes.winnerSliderContentWrap}>
                <div
                  className={[
                    classes.winnerSliderSlideBottomTitle,
                    "font-18 fw-bold text-center",
                  ].join(" ")}
                >
                  <Trans>{t("about_2_slide2_1")}</Trans>
                </div>
                <div
                  className={[
                    classes.winnerSliderSlideBottomSubtitle,
                    "font-16 fw-light text-center",
                  ].join(" ")}
                >
                  <Trans>{t("about_2_slide2_2")}</Trans>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className={[
              classes.winnerSliderSlide,
              classes.winnerSliderSlide6,
            ].join(" ")}
            onMouseEnter={onMouseEnterSlide}
            onMouseLeave={onMouseLeaveSlide}
          >
            <div className={classes.winnerSliderSlideBottom}>
              <div className={classes.winnerSliderImg}>
                <img src={img1} alt="trophey" />
              </div>
              <div className={classes.winnerSliderContentWrap}>
                <div
                  className={[
                    classes.winnerSliderSlideBottomTitle,
                    "font-18 fw-bold text-center",
                  ].join(" ")}
                >
                  <Trans>{t("about_2_slide3_1")}</Trans>
                </div>
                <div
                  className={[
                    classes.winnerSliderSlideBottomSubtitle,
                    "font-16 fw-light text-center",
                  ].join(" ")}
                >
                  <Trans>{t("about_2_slide3_2")}</Trans>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className={classes.eightArrows}>
        <div className={classes.eightArrowsRow}>
          <div
            className={classes.eightArrowsRowLeft}
            onClick={() => {
              my_swiper.slidePrev();
            }}
          >
            <img src={arrow} alt="" />
          </div>
          <div
            className={classes.eightArrowsRowRight}
            onClick={() => {
              my_swiper.slideNext();
            }}
          >
            <img className="no-select" src={arrow} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinnerSlider;
